import React from "react";
import WatchBox from "./watch";
import CampBox from "./camp";
import RankingBox from "./ranking";
import ShopBox from "./shop";

type Props = {};

const ArenaDashboard = (props: Props) => {
  return (
    <div className="arena-dashboard  ">
      <div className="row">
        <div className="col-lg-9">
          <div className="row">
            <div className="col-lg-6 p-0">

              <WatchBox />
            </div>
            <div className="col-lg-6 p-0">  
              <CampBox />
            </div>
            <div className="col-lg-12">
             <ShopBox/>
            </div>
          </div>
        </div>
        <div className="col-lg-3 p-0 pt-1">
          <RankingBox />
        </div>
      </div>
    </div>
  );
};

export default ArenaDashboard;
