import { sep } from "path"
import { IsBeta } from "../utils/Network"

export const TokenLock = {
    id: "tokenlock",
    start: {
        date: Date.UTC(2023, 10, 15, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/tokenlock/coming.webp',
    },
    end: {
        date: Date.UTC(2023, 10, 30, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/tokenlock/started.webp',
    },
    remove: {
        date: Date.UTC(2023, 10, 31, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/tokenlock/ended.webp',
    },
    description: `
    <h1><strong>🍻 CLEG Lock Toast 🍻</strong></h1>
    <p><strong>🍻 Let&rsquo;s make a Toast and Lock CLEG token to earn up to %10 by staking it on &ldquo;PinkLock&rdquo; as a reward.
    
    <br /><br />🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸<br />Here's the deal: <br /> <br /></strong>1️⃣ Stake your $CLEG tokens on Pink Lock (<a target="_blank"  href="https://www.pinksale.finance/pinklock/create?chain=BSC">https://www.pinksale.finance/pinklock/create?chain=BSC</a>). <br /> <br />2️⃣ Fill in the Google form and send us your lock page info!
    (<strong><a target="_blank" href="https://docs.google.com/forms/d/1B3Aqzfl5yJJ-XSl9H_oJIqYL97IwF5xWN4aJYM72wo4/edit">https://forms.gle/ER8VGqLJkzt7odSL9</a></strong>)
    
    <br /><br />3️⃣ After we verify your token lock through, BOOM!⚡️&nbsp;Your Outfit Collection will appear in your game account in 3 working days!<strong><br /><br />🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸
    <br />CLEG Lock info box 👇<br />
    <br /></strong></p>
    <table style="height: 131px;" width="546">
    <tbody>
    <tr>
    <td style="width: 174.625px; text-align: center;"><strong>Amount</strong></td>
    <td style="width: 174.625px; text-align: center;"><strong>Month</strong></td>
    <td style="width: 174.75px; text-align: center;"><strong>Outfit Collection</strong></td>
    </tr>
    <tr>
    <td style="width: 174.625px; text-align: center;">20,000&nbsp;CLEG</td>
    <td style="width: 174.625px; text-align: center;">2</td>
    <td style="width: 174.75px; text-align: center;">1x Guard Outfit</td>
    </tr>
    <tr>
    <td style="width: 174.625px; text-align: center;">20,000&nbsp;CLEG</td>
    <td style="width: 174.625px; text-align: center;">4</td>
    <td style="width: 174.75px; text-align: center;">1x Life Outfit</td>
    </tr>
    <tr>
    <td style="width: 174.625px; text-align: center;">20,000&nbsp;CLEG</td>
    <td style="width: 174.625px; text-align: center;">5</td>
    <td style="width: 174.75px; text-align: center;">&nbsp;1x Aegis Outfit</td>
    </tr>
    </tbody>
    </table>
    <p>&nbsp;</p>
    <p><strong>⚠️ </strong>Each 20000 CLEG = 1x Outfit Collection! It means that, if you lock 40000 CLEG, you'll receive 2x Item set<strong><br /><br />🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸 <br /></strong>📺 And that's not all! We've prepared an easy-to-follow tutorial video to guide you through the entire staking process. <br /> <br />▶️ Check it out on: <br /><strong><a target="_blank"  href="https://youtu.be/ZtLQX4nEHuc?si=irtdT7sXQkc0Erps">https://youtu.be/ZtLQX4nEHuc?si=irtdT7sXQkc0Erps</a>&nbsp;</strong></p>
        `
}


export const BlackFriday = {
    id: "black-friday",
    start: {
        date: Date.UTC(2023, 10, 23, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/black-friday/coming.webp',
    },
    end: {
        date: Date.UTC(2023, 10, 25, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/black-friday/started.webp',
    },
    remove: {
        date: Date.UTC(2023, 10, 27, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/black-friday/ended.webp',
    },
    description: `
    <p style="text-align: center;"><strong><img style="display: block; margin-left: auto;max-width:400px; margin-right: auto;" src="https://blog.chainoflegends.com/wp-content/uploads/2023/11/True-black-FIREday.jpg" width="100%"  /><br /><br />❤️&zwj;🔥 BLACK FIRE DAY ❤️&zwj;🔥</strong></p>
    <p style="text-align: center;">〰️〰️〰️〰️〰️〰️〰️〰️〰️〰️〰️<br /><em>🎉 Happy Black Friday with CLEG Black Fire Day 🎉</em></p>
    <p style="text-align: center;"><strong>⚡️💯 #CLEG Burn 💯⚡️</strong></p>
    <p style="text-align: center;">📆 <strong>Start:</strong> Nov 23rd, 10 AM UTC<br />📆 <strong>End:</strong> Nov 25th, 10AM UTC</p>
    <p style="text-align: center;">✅ Expect items with these standard INO prices and buy them in the marketplace in due time!</p>
    <p style="text-align: center;">💰 Total Items Value<br />🟡 2,000,000 $CLEG<br />🟡 $4000 USDT</p>
    <p style="text-align: center;">〰️〰️〰️〰️〰️〰️〰️〰️〰️〰️〰️<br />⚠️ All of the CLEG spent on buying these Items will be burnt after the event!</p>
    
      `
}

export const Christmas = {
    id: "christmas",
    start: {
        date: "2023-12-01T13:30:00+0330",
        asset: '/assets/images/campaigns/christmas/coming.webp',
    },
    end: {
        date: "2023-12-14T13:30:00+0330",
        asset: '/assets/images/campaigns/christmas/started.webp',
    },
    remove: {
        date: "2023-12-16T13:30:00+0330",
        asset: '/assets/images/campaigns/christmas/ended.webp',
    },
    description: `
    <h2 style="text-align: center;"><strong>🎅🏼❄️ Christmas Frost-Forge ❄️🎅🏼<br /><br /><img class="w-100 rounded" src="https://blog.chainoflegends.com/wp-content/uploads/2023/11/Christmas-2.jpg" /><br /></strong></h2>
<p style="text-align: center;">🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔<br />🎉 Embrace the festive spirit as Christmas Frost-Forge unfolds! Chain of Legends is poised to unveil a myriad of enchanting discounts on the in-game NFTs, essential for reveling in the joy of gameplay and seizing the opportunity to earn $CLEG tokens along the way.</p>
<p style="text-align: center;">❄️ This grand occasion beckons to all who desire to engage in more gameplay and fortify their forces to ascend the ranks within Chain of Legends, unlocking the gateway to double the rewards in adventures. Yes, you heard it right&mdash;prepare to experience a twofold bounty in every adventure, be it within the Dungeons or across the Treasure Islands throughout this joyous season.</p>
<p style="text-align: center;">❗️ But that's not all! A selection of "Secret keys" is poised to be revealed, granting fortunate holders the ability to beckon forth a wider audience. Possessors of these secret keys will amass supplementary points on an external platform, paving the way to claiming additional rewards!</p>
<p style="text-align: center;">🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔<br /><strong>🎁 Discount List:</strong></p>
<p style="text-align: center;">Build &amp; Upgrade CLEG Mine = 10-70% Off<br />Build &amp; Upgrade Stone Mine = 10-70% Off<br />Build &amp; Upgrade Iron Mine = 10-70% Off<br />Build &amp; Upgrade Barracks = 10-70% Off<br />Train &amp; Upgrade Heroes = 10% Off</p>
<p style="text-align: center;">🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔🔔<br /><strong>🎁 2X Festive Loot</strong></p>
<p style="text-align: center;">💀 Dungeons = 2x<br />🏝 Treasure Islands = 2x</p>
<p style="text-align: center;">⚠️ The twofold bounty will be ripe for the difficulties you have yet to encounter</p>
    
      `
}

export const ChristmasStarted = {
    id: "christmas-started",
    start: {
        date: Date.UTC(2023, 11, 16, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/christmas-started/coming.webp',
    },
    end: {
        date: Date.UTC(2023, 11, 28, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/christmas-started/started.webp',
    },
    remove: {
        date: Date.UTC(2023, 11, 29, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/christmas-started/ended.webp',
    },
    description: `
    <p style="text-align: center;"><strong>🎁🎅 Christmas Santa Bounty 🎅🎁<br /><br /><img class="w-100 rounded" style="max-width:600px" src="https://blog.chainoflegends.com/wp-content/uploads/2023/12/Santa-Bounty.jpg" /><br /></strong></p>
    <p style="text-align: center;">🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹<br />🤩 Santa came back to your Empire with a more astonishing Bounty than before!<br />Train Stronger Army<br />Define a Strategy<br />Join Forces<br />&amp; <br />Aim for ...</p>
    <p style="text-align: center;">🤑 <strong>3X LOOT</strong> 🤑</p>
    <p style="text-align: center;">📆 Start: December 16th<br />📆 End: December 28th</p>
    <p style="text-align: center;">🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹<br />⚠️ The 3x Loot only applies to you for winning a new difficulty!</p>
    `
}





export const GuildEvent = {
    id: "guild",
    timer: "/assets/images/campaigns/guild/timer1.webp",
    start: {
        date: IsBeta ? Date.UTC(2024, 1, 1, 10, 0, 0, 0) : Date.UTC(2024, 1, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/guild/coming.webp',
    },
    end: {
        date: Date.UTC(2024, 2, 1, 0, 0, 0, 0),
        asset: '/assets/images/campaigns/guild/started.webp',
    },
    remove: {
        date: Date.UTC(2024, 2, 2, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/guild/ended.webp',
    },
    description: `
    <h2 style="text-align: center;">🌟💬 Guild Alliance; Contest Details 💬🌟<br /><br /><img class="w-100 rounded" src="https://blog.chainoflegends.com/wp-content/uploads/2024/01/3-1.jpg" /></h2>
    <p style="text-align: center;">🏅 <strong>Guild Owner Ranking:</strong> <br />The monthly score determined by the spending cap of your Guild members will decide your standing. The more your members invest, the higher your Guild score gets. At the end of the contest, we'll distribute rewards based on the Guild Leader's ranking in the monthly "Society Builders" tab!</p>
    <p style="text-align: center;">🎁<strong> Contest Rewards:</strong><br />- Top Community Owners: Prize varies from 2k - 20k $CLEG for the top 20 winners.<br />- Guild Members: Win exclusive NFT Item Sets such as the Unique, Life, Guard, and Aegis sets, based on the Leader's ranking.</p>
    <p style="text-align: center;">🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸<br />🏆<strong> 1st Community Prize (Approximately $2000):</strong><br />- $6 worth of NFT starter pack for every new player.<br />- $9 worth of NFT Items for every participant as a Ranking Reward.<br />- Community owner receives a grand reward of 20k CLEG + 30k CLEG.</p>
    <p style="text-align: center;"><strong>🥈 2nd - 4th Community Prize (Approximately $1500 each):</strong><br />- $6 worth of NFT starter pack for every new player.<br />- $6 worth of NFT Items for every participant as a Ranking Reward.<br />- Community owner gains 10k CLEG + 30k CLEG.</p>
    <p style="text-align: center;"><strong>🥉 5th - 10th Community Prize (Approximately $1200 each):</strong><br />- $6 worth of NFT starter pack for every new player.<br />- $3 worth of NFT Items for every participant as a Ranking Reward.<br />- Community owner is rewarded with 5k CLEG + 30k CLEG.</p>
    <p style="text-align: center;"><strong>🏅 11th - 20th Community Prize (Approximately $1000 each):</strong><br />- $6 worth of NFT starter pack for every new player.<br />- $2 worth of NFT Items for every participant as a Ranking Reward.<br />- Community owner earns 2k CLEG + 30k CLEG.</p>
    <p style="text-align: center;"><strong>🎁 Total Prize Pool: $26000 worth of $CLEG and NFTs!</strong></p>
    <p style="text-align: center;">🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸🔸<br />✅ Ready to embark on a rewarding adventure? Sign up for the Guild Alliance Contest via Google form to unlock a world of possibilities and exciting treasures for both you and your community!</p>
    <p style="text-align: center;"><strong>📮 Sign-up Here 👉 <a href="https://forms.gle/h1ZWFDPAPP4EZcwG9" target="_blank">https://forms.gle/h1ZWFDPAPP4EZcwG9</a></strong></p>
      `
}


export const LunarEvent = {
    id: "lunar",
    timer: "/assets/images/campaigns/lunar/timer.webp",
    start: {
        date: IsBeta ? Date.UTC(2024, 1, 1, 10, 0, 0, 0) : Date.UTC(2024, 1, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/lunar/coming.webp',
    },
    end: {
        date: Date.UTC(2024, 2, 1, 0, 0, 0, 0),
        asset: '/assets/images/campaigns/lunar/started.webp',
    },
    remove: {
        date: Date.UTC(2024, 2, 2, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/lunar/ended.webp',
    },
    description: `<h1 style="text-align: center;">Lunar New Year; Barracks Discounts</h1>
    <p style="text-align: center;"><img width="260px" src="https://blog.chainoflegends.com/wp-content/uploads/2024/02/image_2022-02-01_16-48-13.png" /><br />🎉 Join us from February 16th to February 20th for an exclusive opportunity to elevate your Barracks and amplify your fortunes. The dragon's fire beckons you to seize these incredible upgrade discounts:</p>
    <p style="text-align: center;">Level 0️⃣➖1️⃣ (60% off)<br />Level 1️⃣➖2️⃣ (50% off)<br />Level 2️⃣➖3️⃣ (40% off)<br />Level 3️⃣➖4️⃣ (30% off)<br />Level 4️⃣➖5️⃣ (20% off)<br />Level 5️⃣➖6️⃣ (10% off)<br />Level 6️⃣➖7️⃣ (10% off)<br />Level 7️⃣➖8️⃣ (10% off)</p>
    <p style="text-align: center;">🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹<br /><strong>⚔️ Why are these upgrades crucial?</strong> <br />In the days ahead, the much-anticipated release of "Guilds" draws near. To partake in this exciting feature, aspiring Guild leaders must have a Barracks level 8 to lay the foundation for their Guild. Furthermore, those seeking to join a Guild must aim for a Barracks level 7.</p>
    <p style="text-align: center;"><strong>🚀 Prepare for the future!</strong><br />Elevate your gameplay, and secure your place in the Guild community with these essential upgrades during the Lunar New Year (Dragon Fire) Event. Ignite your journey to prosperity and position yourself for a remarkable adventure ahead!</p>
    <p style="text-align: center;">🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹🔹<br /><strong>👇 Click &amp; Enjoy Upgrade Discounts 👇</strong><br /><br /><a href="/home#upgrade-barracks" >Upgrade Barracks</a></p>
            `
}


export const JuneEvent = {
    id: "egg",
    timer: "/assets/images/campaigns/lunar/timer.webp",
    start: {
        date: Date.UTC(2024, 5, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/june/event-borad-challenge-june-barracks.png',
    },
    end: {
        date: Date.UTC(2024, 6, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/june/event-borad-challenge-june-barracks.png',
    },
    remove: {
        date: Date.UTC(2024, 6, 2, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/june/event-borad-challenge-june-barracks.png',
    },
    description: `<div class="text-center">
<h1 style="text-align: center;">🎉 JunE Fair 🎉</h1>
<img width="100%" style="max-width:500px;" src="https://blog.chainoflegends.com/wp-content/uploads/2024/06/photo_2024-06-22_14-12-36.jpg" />
<p style="text-align: center;"><span style="text-align: center; color: #fff;">Utilize "June Upgrade Mojo" to upgrade your Heroes</span></p>
<p style="text-align: center;"><span style="text-align: center; color: #fff;">Take 2x loot for winning new Dungeon &amp; Treasure Island difficulties</span></p>
<p style="text-align: center;">--------------------------------------</p>
<p style="text-align: center;"><strong>ℹ️ How much Discount?</strong></p>
<p style="text-align: center;">%25 Discount ... Upgrade Warriors All levels<br />%20 Discount ... Upgrade Archers All levels<br />%15 Discount ... Upgrade Guardians All levels<br />%10 Discount ... Upgrade Knights All levels<br />%5 Discount ... Upgrade Mages All levels<br />%1 Discount ... Upgrade Catapults All levels<br /><br /></p>
<p style="text-align: center;"><strong>⏰ Troop Upgrade Mojo period ⏰</strong><br /> June 21st - June 30th | 10 AM UTC</p>
<p style="text-align: center;">⚠️ If you upgrade 2 times or 3 times, You'll get the discount on all of them!</p>
<a class="text-center" href="/barracks" ><br />👉 Fortify &amp; 2x Loot Right Now 👈</h2> </a>
 </div>`
}


export const Challenge = {
    id: 'challenge',
    timer: "/assets/images/campaigns/challenge-timer.webp",
    start: {
        date: Date.UTC(2024, 8, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/event-board-sep-challenge.webp',
    },
    end: {
        date: Date.UTC(2024, 9, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/event-board-sep-challenge.webp',
    },
    remove: {
        date: Date.UTC(2024, 9, 2, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/event-board-sep-challenge.webp',
    },
    description: `<div class="text-center">
    <h1 style="text-align: center;">🎉 JunE Fair 🎉</h1>
    <img width="100%" style="max-width:500px;" src="https://blog.chainoflegends.com/wp-content/uploads/2024/06/photo_2024-06-22_14-12-36.jpg" />
    <p style="text-align: center;"><span style="text-align: center; color: #fff;">Utilize "June Upgrade Mojo" to upgrade your Heroes</span></p>
    <p style="text-align: center;"><span style="text-align: center; color: #fff;">Take 2x loot for winning new Dungeon &amp; Treasure Island difficulties</span></p>
    <p style="text-align: center;">--------------------------------------</p>
    <p style="text-align: center;"><strong>ℹ️ How much Discount?</strong></p>
    <p style="text-align: center;">%25 Discount ... Upgrade Warriors All levels<br />%20 Discount ... Upgrade Archers All levels<br />%15 Discount ... Upgrade Guardians All levels<br />%10 Discount ... Upgrade Knights All levels<br />%5 Discount ... Upgrade Mages All levels<br />%1 Discount ... Upgrade Catapults All levels<br /><br /></p>
    <p style="text-align: center;"><strong>⏰ Troop Upgrade Mojo period ⏰</strong><br /> June 21st - June 30th | 10 AM UTC</p>
    <p style="text-align: center;">⚠️ If you upgrade 2 times or 3 times, You'll get the discount on all of them!</p>
    <a class="text-center" href="/barracks" ><br />👉 Fortify &amp; 2x Loot Right Now 👈</h2> </a>
     </div>`
}



const augustEvent = {
    id: "august",
    timer: "/assets/images/campaigns/august/timer.webp",
    start: {
        date: Date.UTC(2024, 7, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/august/coming.webp',
    },
    end: {
        date: Date.UTC(2024, 8, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/august/started.webp',
    },
    cycles: [
        {
            id: "august-1",
            startDate: Date.UTC(2024, 7, 1, 10, 0, 0, 0),
            asset: '/assets/images/campaigns/august/event-board-august-1.webp',
            link: "/home#upgrade-cleg",
        },
        {
            id: "august-2",
            startDate: Date.UTC(2024, 7, 6, 10, 0, 0, 0),
            asset: '/assets/images/campaigns/august/event-board-august-2.webp',
            link: "/home#upgrade-stone",
        },
        {
            id: "august-3",
            startDate: Date.UTC(2024, 7, 11, 10, 0, 0, 0),
            asset: '/assets/images/campaigns/august/event-board-august-3.webp',
            link: "/home#upgrade-iron",

        },
        {
            id: "august-4",
            startDate: Date.UTC(2024, 7, 16, 10, 0, 0, 0),
            asset: '/assets/images/campaigns/august/event-board-august-4.webp',
            link: "/home#upgrade-barracks",

        },
        {
            id: "august-5",
            startDate: Date.UTC(2024, 7, 21, 10, 0, 0, 0),
            asset: '/assets/images/campaigns/august/event-board-challenge-august-5.webp',
            link: "/barracks",

        },
    ]
}

const septemberEvent = {
    id: "september",
    timer: "https://static.chainoflegends.com/images/campaigns/arena-campaign/timeer-box.webp",
    link:'/arena/leader-board/lords',
    start: {
        date: Date.UTC(2024, 9, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/august/coming.webp',
    },
    end: {
        date: Date.UTC(2024, 10, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/august/started.webp',
    },
    cycles: [
        {
            id: "september-1",
            startDate: Date.UTC(2024, 9, 1, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/arena-campaign/Event-borad-arena-battle-new-1-up.webp",
        },
        {
            id: "september-2",
            startDate: Date.UTC(2024, 9, 7, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/arena-campaign/Event-borad-arena-battle-new-1-live.webp",
        },
        {
            id: "september-3",
            startDate: Date.UTC(2024, 9, 14, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/arena-campaign/Event-borad-arena-battle-new-2-up.webp",

        },
        {
            id: "september-4",
            startDate: Date.UTC(2024, 9, 21, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/arena-campaign/Event-borad-arena-battle-new-2-live.webp",
        },
        {
            id: "september-5",
            startDate: Date.UTC(2024, 9, 26, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/arena-campaign/Event-borad-arena-battle-new-3-up.webp",
        },
    ]
}
const octoberEvent = {
    id: "october",
    timer: "https://static.chainoflegends.com/images/campaigns/october-arena/timeer-box.png",
    start: {
        date: Date.UTC(2024, 9, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/august/coming.webp',
    },
    end: {
        date: Date.UTC(2024, 10, 1, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/august/started.webp',
    },
    cycles: [
        {
            id: "october-1",
            startDate: Date.UTC(2024, 9, 1, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/october-arena/october-discounts-Event-board-1.png",
            link: "/home#upgrade-cleg",
       
        },
        {
            id: "october-2",
            startDate: Date.UTC(2024, 9, 6, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/october-arena/october-discounts-Event-board-2.png",
            link: "/home#upgrade-stone",
        },
        {
            id: "october-3",
            startDate: Date.UTC(2024, 9, 11, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/october-arena/october-discounts-Event-board-3.png",
            link: "/home#upgrade-iron",

        },
        {
            id: "october-4",
            startDate: Date.UTC(2024, 9, 16, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/october-arena/october-discounts-Event-board-4.png",
            link: "/home#upgrade-barracks",
        },
        {
            id: "october-5",
            startDate: Date.UTC(2024, 9, 25, 10, 0, 0, 0),
            asset: "https://static.chainoflegends.com/images/campaigns/october-arena/october-discounts-Event-board-5.png",
            link: "/barracks",
       
        },
    ]
}

export const SeptemberEvent = {
    id: "september-x",
    timer: "https://static.chainoflegends.com/images/campaigns/arena-campaign/timeer-box.webp",
    start: {
        date: Date.UTC(2024, 8, 1, 10, 0, 0, 0),
        asset: 'https://static.chainoflegends.com/images/campaigns/arena-campaign/Event-borad-arena-battle-new-1-up.webp',
    },
    end: {
        date: Date.UTC(2024, 8, 1, 0, 0, 0, 0),
        asset: '/assets/images/campaigns/lunar/started.webp',
    },
    remove: {
        date: Date.UTC(2024, 8, 2, 10, 0, 0, 0),
        asset: '/assets/images/campaigns/lunar/ended.webp',
    },
    description: `
    <h2>Arena Battles Competitions</h2>
<h3>Stage 1: Valor Arena Battles (Public Matches) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h3>
<h4>Duration: Sep 1st - Sep 15th</h4>
<p>Everyone receives&nbsp;<strong>5 free Energy daily</strong>, to spend them participating in Arena battles. Each Energy is an entry opportunity to 1 battle:</p>
<p>There is definitely a <strong>Leaderboard</strong> representing the collected points that players achieved by joining the Arena battles. Now one question comes to mind: How to collect points?</p>
<p>-Each victory rewards <strong>2 points.</strong></p>
<p>-Each loss rewards <strong>1 point.</strong></p>
<p>&nbsp;</p>
<h3>Stage 2: Legion Arena Battles (Group Stage Matches)</h3>
<h4>Duration: Sep 19th - Sep 25th</h4>
<p>Who can join?&nbsp;<strong>Top 48 players&nbsp;from the stage 1</strong></p>
<p>Here you are only allowed to enter&nbsp;the&nbsp;<strong>P2E Mode</strong> with the same principles as the previous stage, to beat your opponents. The set rules will make you demonstrate your unique talents in wisely strategizing despite the limitations, and show everyone what you're made of!</p>
<p>The 48 participants will be divided into eight <strong>groups of six,</strong> each group, an arena of competition. Each fighter must navigate&nbsp;five battles within their group, striving for&nbsp;3 victories to secure their place for the next stage.&nbsp;To be a winner in each battle of this round, a contestant must gain at least 2 victories from 3 encounters against his/her opponent. Therefore you have 1 battle every day of the duration between <strong>10 to 14 UTC</strong>.&nbsp;Time scheduling of each will be managed via Discord; stay tuned on Discord! &nbsp;</p>
<p>The probable key to battlefield dominance lies within the walls of your Barracks! Unlocking their full potential by upgrading it will empower your heroes, transforming them into a formidable army. Enjoy higher Bonus stats of your heroes by higher levels of your Barracks!</p>
<p>&nbsp;</p>
<h3>Stage 3: Legends Arena Battles (Play-Off Matches)</h3>
<h4>Duration: Sep 28th - Sep 30th</h4>
<p>The 24 winners of “Group Stage Matches” will be divided into eight groups of 3; the groups will encounter each other this time, which means legions of 3 members for 3v3 battles. So say hello to your comrades, be in touch, and start planning to strategize your army together.&nbsp;</p>
<p>Every contestant in a raid will enter&nbsp;the&nbsp;<strong>P2E Mode</strong> of Arena to deploy their own heroes and attached items (not exceeding the troop’s level) in the battles, and will choose a maximum of&nbsp;10&nbsp;troops not exceeding a total cost of&nbsp;<strong>100,000 CLEG</strong></p>
<p>By witnessing only 8 battles which would be scheduled in 3 days, the places of the 8 groups will be revealed. On the first day, <strong>4</strong> battles take place from <strong>10 to 14 UTC</strong>: the tournament will narrow with four groups eliminated and four others rising to claim the top four positions. The second day will evidence a hard struggle for the third and fourth-place positions from <strong>12 to 14 UTC</strong>. Then, in a breathtaking finale, the last day at the same time, the two remaining groups will face off in a battle that will determine the champion of champions.Time scheduling of each will be managed via Discord; stay tuned on Discord! &nbsp;</p>
<p>&nbsp;</p>
<h3>Rewards:</h3>
<p><strong>- 5th to 8th place: 1.25% of the USDT prize pool + Blood Gem</strong></p>
<p><strong>- 2nd Place: 20% of the USDT prize pool + Blood Gem</strong></p>
<p><strong>- 3rd Place: 10% of the USDT prize pool + &nbsp;Blood Gem</strong></p>
<p><strong>- 4th Place: 5% of the USDT prize pool + Blood Gem</strong></p>
<p><strong>- 1st Place: 60% of the USDT prize pool + Blood Gem</strong></p>
<p>&nbsp;</p>
    
    `
}


export default [septemberEvent,octoberEvent]
