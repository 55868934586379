import React from "react";
import MidBox from "../../../../components/Boxes/MidBox";
import WatchBoxEmptyState from "./empty-state";
import classNames from "classnames";
import { Link } from "react-router-dom";
import useWatch from "../../../hooks/useWatch";
import Button from "../../../../components/Button";
import { CustomIcon } from "../../../../components/Icons";
import truncateString from "../../../../utils/truncate-string";

type Props = {};

const WatchBox = (props: Props) => {
  const { battleList, connector } = useWatch();

  return (
    <MidBox
      noTitle
      noMargin
      title="Watch"
      className="w-100"
      containerClass="px-lg-4 "
      style={{ minHeight: 195 }}
    >
      <img
        src="/assets/images/arena/icons/watch.png"
        width={86}
        className="box-logo"
        alt="watch-bg"
      />
      <div style={{ marginTop: -20 }} className="mx-3">
        {battleList && battleList?.length > 0 ? (
          <div>
            <div className="row gap-2">
              {battleList?.map((item) => (
                <div className="col-12 d-flex rounded bg-2 justify-content-between align-items-center p-1 py-2">
                  <div>
                    {item?.attackerPlayers.map((player, index) => (
                      <div
                        className="d-flex flex-column align-items-center "
                        key={player.playerId}
                      >
                        <img
                          src={player.avatar}
                          className="circle"
                          width={55}
                          height={55}
                        />
                        <div
                          style={{ maxWidth: 70 }}
                          className="fs-small text-white text-truncate"
                        >
                          {player.username}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    <CustomIcon icon="blades" width={25} height={25} />
                  </div>
                  {item?.defenderPlayers.map((player, index) => (
                    <div
                      className="d-flex flex-column align-items-center"
                      key={player.playerId}
                    >
                      <img
                        src={player.avatar}
                        className="circle"
                        width={55}
                        height={55}
                      />
                      <div className="fs-small text-white">
                        {player.username}
                      </div>
                    </div>
                  ))}
                  <div className="d-flex  align-items-center">
                    {Boolean(item?.watchers) && item?.watchers! > 0 && (
                      <>
                        <CustomIcon icon="eye" width={20} height={15} />
                        {item?.watchers}
                      </>
                    )}
                  </div>
                  <div>
                    <Button
                      size="small"
                      style={{ minWidth: "200px !important" }}
                      onClick={() => connector?.watchBattle(item?.battleId)}
                      variant="primary"
                    >
                      Watch
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            style={{ minHeight: 200 }}
            className={classNames(
              "camp-box-content position-relative  bg-2 rounded-3  d-flex flex-column  p-3",
              {
                "justify-content-center align-items-center": true,
                // !isLoading && playerTroops.length === 0,
              }
            )}
          >
            <WatchBoxEmptyState />
          </div>
        )}
      </div>
      <Link className="w-100 text-center d-block" to="/arena/watch">
        <a className="dark-yellow mt-2 fs-small d-block font-normal ">
          See All
        </a>
      </Link>
    </MidBox>
  );
};

export default WatchBox;
