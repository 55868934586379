import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { isMobile } from "react-device-detect";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LAND_TABS, MAIN_TABS } from "../../../constants/marketPlace";
import {
  MarketPlaceLandTabsType,
  MarketPlaceMainTabsType,
} from "../../../types/pages/MarketPlace";
import classNames from "classnames";

type Props = {};

const MarketPlaceTabs = (props: Props) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [activeTabIndex, setActiveTabIndex] = useState(0); // Track active tab index
  const [indicatorX, setIndicatorX] = useState(0); // TranslateX for the indicator
  const [indicatorWidth, setIndicatorWidth] = useState(50); // Width of the indicator
  const tabContainerRef = useRef<HTMLDivElement>(null); // Ref to track the container

  function handleChangeType(active: number, index: number) {
    navigate({
      pathname: "/marketplace",
      search: "type=" + active,
    });
    setActiveTabIndex(index); // Update the active tab index
  }

  function handleChangeTab(active: number) {
    navigate({
      pathname: "/marketplace",
      search: "id=" + active,
    });
  }



  const { type, landId } = React.useMemo(() => {
    const typeParam = searchParams.get("type");
    const landIdParam = searchParams.get("id");
    return {
      type: typeParam ? +typeParam : 0,
      landId: landIdParam ? +landIdParam : 1,
    };
  }, [searchParams]);

  useEffect(() => {
    const typeParam = searchParams.get("type");
    if(!typeParam) return  setActiveTabIndex(1);
    setActiveTabIndex(+typeParam+1); // Update the active tab index
  }, []);

  useEffect(() => {
    // Dynamically set tab positions after the DOM is rendered
    const updateTabPosition = () => {
      const tabElements = tabContainerRef.current?.querySelectorAll('.marketplace-tab');
      if (tabElements && tabElements[activeTabIndex]) {
        const activeTab = tabElements[activeTabIndex] as HTMLElement;
        const rect = activeTab.getBoundingClientRect();
        const containerRect = tabContainerRef.current?.getBoundingClientRect();
        const offsetLeft = rect.left - (containerRect?.left || 0);
        setIndicatorX(offsetLeft+rect.width/4);
        setIndicatorWidth(rect.width/2);
      }
    };

    updateTabPosition();

    // Add an event listener for resize to update positions dynamically
    window.addEventListener('resize', updateTabPosition);

    return () => {
      window.removeEventListener('resize', updateTabPosition);
    };
  }, [activeTabIndex, type]);

  return (
    <>
      <motion.div
        initial={isMobile ? undefined : { opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        exit={{ opacity: 0 }}
        className="tab-bar mt-4"
      >
        <PerfectScrollbar style={{ maxWidth: "100%" }}>
          <div
            className="row flex-nowrap m-0 p-0 justify-content-start justify-content-md-center position-relative"
            ref={tabContainerRef} // Reference to the tab container
          >
            {MAIN_TABS.map((tab: MarketPlaceMainTabsType, index: number) => (
              <button
                onClick={(e) => {
                  if (tab.disabled) return e.preventDefault();
                  handleChangeType(tab.type, index);
                }}
                key={tab?.type}
                className={classNames(
                  `marketplace-tab col-2 col-sm-4 pb-4 pt-3 col-md-3 col-lg-2 position-relative`,
                  tab.className,
                  {
                    active: tab.type === type,
                    "button-disabled": tab.disabled,
                  }
                )}
              >
                <div className="b-text">
                  <span className="text-light fs-med d-flex flex-column align-items-center fs-lg fs-sm-small">
                    <img
                      className="mb-1 circle fs-med"
                      alt={tab?.title}
                      src={tab.imageSrc}
                      width="55px"
                      height="55px"
                    />
                    {tab.title}
                  </span>
                </div>
              </button>
            ))}

            {indicatorX !== null && (
              <motion.div
                className="position-absolute bg-dark-yellow"
                style={{
                  top: 0,
                  left: 0,
                  height: "4px",
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
                }}
                animate={{
                  x: indicatorX, // Move the indicator based on the active tab's X position
                  width: indicatorWidth, // Adjust width based on the active tab's width
                }}
                transition={{ duration: 0.3 }}
              />
            )}
          </div>
        </PerfectScrollbar>
      </motion.div>

      {type === 0 && (
        <motion.div
          initial={isMobile ? undefined : { y: -125, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3, delay: 0.1 }}
          exit={{ y: 200, opacity: 0 }}
          className="tab-bar mb-4"
          style={{ borderRadius: 0, marginTop: -5, zIndex: 10 }}
        >
          <PerfectScrollbar style={{ maxWidth: "100%" }}>
            <div className="row flex-nowrap m-0 p-0 justify-content-start justify-content-md-center">
              {LAND_TABS.map((tab: MarketPlaceLandTabsType) => (
                <button
                  onClick={() => {
                    handleChangeTab(tab.id);
                  }}
                  key={tab?.id}
                  className={
                    `col-2 col-sm-4 pb-4 pt-3 col-md-3 col-lg-2 ${tab.className} ` +
                    (tab.id === landId && "active")
                  }
                >
                  <div className="text-light d-flex flex-column align-items-center  fs-lg fs-sm-small">
                    <img
                      className="me-0 me-sm-1 w-100"
                      alt={tab?.title}
                      style={{ maxWidth: "55px", maxHeight: "55px" }}
                      src={tab.imageSrc}
                    />
                    <div className="mt-1 fs-med">{tab.title}</div>
                  </div>
                </button>
              ))}
            </div>
          </PerfectScrollbar>
        </motion.div>
      )}
    </>
  );
};

export default MarketPlaceTabs;