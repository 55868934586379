import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./styles.css";
import TroopsFormation from "../../screens/troops-formation";
import ArenaHomeLayout from "../../layouts/arena-layout";
import Camp from "../camp";
import CampLayout from "../../layouts/camp-layout";
import ArenaDashboard from "../dashboard";
import FriendlyMatchBox from "./friendly-match";
import MatchSuggestion from "./match-suggestion";
import BattleField from "../battle-field";
import JoinBattle from "./join-battle";
import ComingSoon from "../coming-soon";
import useWindowSize from "../../../hooks/useWindowSize";
import ConnectionLoading from "../connection-loading";
import { ModelLoaderProvider } from "../../context/model-loader-context";
import ArenaLeaderBoard from "../../screens/leader-board";
import {} from "@sentry/react";

type Props = {};

const ArenaNavigator = (props: Props) => {
  const windowSize = useWindowSize();

  return (
    <ModelLoaderProvider>
      <div className="arena-container">
        {windowSize.width < 768 && <ComingSoon />}
       {/* <ConnectionLoading /> */}
        <Routes>
          <Route path="/live-battle" element={<BattleField />} />
          <Route path="/formation" element={<TroopsFormation />} />
          <Route path="/camp" element={<CampLayout />}>
            <Route index element={<Camp />} />
          </Route>
          <Route path="/" element={<ArenaHomeLayout />}>
            {/* Nested routes */}
            <Route index element={<ArenaDashboard />} />
            <Route path="/host-match" element={<FriendlyMatchBox />} />
            <Route path="/join-match" element={<JoinBattle />} />
            <Route path="/leader-board/:type" element={<ArenaLeaderBoard/>} />
            <Route path="/leader-board" element={<Navigate to="/arena/leader-board/lords"/>} />
            <Route
              path="/battle-lobby"
              element={<MatchSuggestion timeout={30000} isJoinMatch={false} />}
            />
            {/* <Route path="*" element={<Navigate to="/arena/camp" />} /> */}
          </Route>
        </Routes>
      </div>
    </ModelLoaderProvider>
  );
};

export default ArenaNavigator;
