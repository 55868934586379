import React, { useEffect, useRef } from "react";
import MidBox from "../../../../components/Boxes/MidBox";
import EmptyState from "../../../../components/EmptyState";
import CampBoxEmptyState from "./empty-state";
import useCamp from "../../../hooks/useCamp";
import classNames from "classnames";
import { Link } from "react-router-dom";
import CircularProgress from "../../../../components/CircularProgress";

type Props = {};


// TODO: add a hook for the troops count and level

const CampBox = (props: Props) => {
  const { fetchTroops, playerTroops, isLoading, isMoveLoading, moveTroop } =
    useCamp();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;
    fetchTroops();
  }, [fetchTroops]);

  // TODO: Add Loading
  return (
    <MidBox
      title="Camp"
      className="w-100"
      noMargin
      containerClass="px-0 px-lg-4 "
      noTitle
    >
      <img
        src="/assets/images/arena/icons/dart.png"
        width={86}
        className="box-logo"
        alt="watch-bg"
      />
      <div style={{ marginTop: -20 }} className="mx-3">
        <div
          style={{ minHeight: 200 }}
          className={classNames(
            "camp-box-content position-relative  bg-2 rounded-3  d-flex flex-column  p-3",
            {
              "justify-content-center align-items-center":
                !isLoading && playerTroops.length === 0,
            },
            {'align-items-center justify-content-center': isLoading}
          )}
        >
          {isLoading &&<CircularProgress/> }
          {!isLoading && playerTroops.length === 0 && <CampBoxEmptyState />}
          {!isLoading && playerTroops.length > 0 && (
            <div className="w-100 row ">
              {playerTroops.map((troop) => {
                return (
                  <div className="col-3 p-0" key={troop.id}>
                    <div className="troop-frame position-relative">
                      <img
                        className="circle m-1 scaleX w-100 "
                        width={"100%"}
                        src={troop.troop.icon}
                        alt={troop.troop.name}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {playerTroops.length > 0 && (
          <Link className="w-100 text-center d-block" to="/arena/camp">
            <a className="dark-yellow mt-2 fs-small d-block font-normal ">Manage Troops</a>
          </Link>
        )} 
      </div>
    </MidBox>
  );
};

export default CampBox;
