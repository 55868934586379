import React, { act, useEffect, useMemo, useState } from "react";
import MidBox from "../../../components/Boxes/MidBox";
import { useBattleContext } from "../../context";
import { AnimatePresence, motion } from "framer-motion";
import {
  BattleModeInfo,
  FormationCampArmy,
  FormationCampArmyItem,
  Troop,
} from "../../types";
import classNames from "classnames";
import ModelPreview from "./model-preview";
import getModelsData from "../../utils/get-model-data";
import Slider from "rc-slider";
import Button from "../../../components/Button";
import { CustomIcon } from "../../../components/Icons";
import getTroopPrice from "../../utils/get-troop-price";
import ThousandsSeparator from "../../../utils/thousands-separator";
import CountDown from "react-countdown";
import { createTempID } from "../../screens/troops-formation";

type Props = {
  onSelect: (troop: Troop | FormationCampArmy) => void;
  selectedTroops: Troop[];
  activeTroop?: Troop | FormationCampArmy;
  onSubmit: (troop: Troop) => void;
  changeLevel: (level: number) => void;
  onRemove: (troop: Troop) => void;
  isReady: boolean;
  onReadyCheck: () => void;
  onNotReady: () => void;
  battleModeInfo?: BattleModeInfo;
  army?: FormationCampArmy[];
  isMyArmyOnly?: boolean;
};

const Timer = ({
  date,
  onComplete,
}: {
  date: number;
  onComplete?: () => void;
}) => {
  return (
    <div className="formation-timer px-2">
      <CountDown
        date={date}
        onComplete={onComplete}
        renderer={({ minutes, completed, formatted }) => {
          if (completed) {
            return <div className="text-center">Time's up!</div>;
          }
          return (
            <>
              <CustomIcon icon={"timer"} type="png" width={20} height={20} />
              {minutes}:{formatted.seconds}
            </>
          );
        }}
      />
    </div>
  );
};
const TroopSelection = ({
  onSelect,
  selectedTroops,
  activeTroop,
  army,
  changeLevel,
  onRemove,
  onSubmit,
  isReady,
  onReadyCheck,
  battleModeInfo,
  onNotReady,
  isMyArmyOnly,
}: Props) => {
  const { battleState, gameInfo, messages } = useBattleContext();
  const { troops } = battleState;
  const troopLength = selectedTroops.length;
  const previewModel = activeTroop
    ? getModelsData(activeTroop.id || activeTroop.troopId)
    : undefined;

  const myArmy = useMemo(() => {
    let newArmy = [...(army || [])];
    newArmy.map(({ troopId, level }, i) => {
      const troop = troops.find((t) => t.id === troopId);
      newArmy[i].icon = troop?.icon;
      newArmy[i].clegCost = troop?.clegCost;
      newArmy[i].tempId = createTempID();
      return troop;
    });
    return newArmy;
  }, [army, troops]);

  const MaxCost = battleModeInfo?.armyValue || 100000;

  const totalCost = useMemo(
    () => selectedTroops.reduce((acc, curr) => acc + curr.clegCost, 0),
    [selectedTroops]
  );

  const formationTimeOut = useMemo(() => {
    if (!gameInfo?.gameRules) return 0;
    return new Date(
      Date.now() + gameInfo?.gameRules?.formationTimeout!
    ).getTime();
  }, [gameInfo]);
useEffect(() => {
console.log(selectedTroops,activeTroop  )
}, [selectedTroops])
  return (
    <div
      className={classNames(
        "row w-100 justify-content-start align-items-center",
        {}
      )}
    >
      {Boolean(formationTimeOut) && (
        <Timer
          date={formationTimeOut}
          onComplete={() => {
            if (!isReady) onReadyCheck();
          }}
        />
      )}
      <div className="col-12 col-md-6 col-lg-4">
        <MidBox modal title="Character Selection" className="w-100">
          <div className="row ">
            {isMyArmyOnly ? (
              myArmy.length === 0 ? (
                <div style={{marginBottom:-60}} className="d-flex  justify-content-center align-items-center">
            
                  <div className="fs-md bg-dark-500 d-flex align-items-center bg-blur p-2 rounded text-white">
                  <CustomIcon
                    icon="warning"
                    className="scaleX me-1"
                    width={20}
                    height={20}
                  />  You have no troops in your army
                  </div>
                </div>
              ) : (
                myArmy.map((troop, index) => (
                  <div
                    className="col-12 col-md-6 col-lg-4 col-xl-3 p-0"
                    key={index}
                  >
                    <motion.div
                      onClick={() =>  !Boolean(selectedTroops.find(t => t.playerTroopId === troop.playerTroopId)) && onSelect(troop)}
                      initial={{ scale: 0.5 }}
                      animate={{
                        scale: activeTroop?.tempId === troop.tempId ? 1.06 : 1,
                      }}
                      className={classNames("troop-frame position-relative", {
                        "disabled": Boolean(selectedTroops.find(t => t.playerTroopId === troop.playerTroopId)),
                        selected: activeTroop?.tempId === troop.tempId,
                      })}

                    >
                      <div
                        style={{ width: 20, height: 20, top: 5, right: 5 ,zIndex:20}}
                        className="level d-flex align-items-center justify-content-center bg-dark-yellow circle position-absolute  text-dark"
                      >
                        {troop.level}
                      </div>
                      <img
                        className="circle scaleX w-100 m-1 "
                        alt={troop.tempId + ""}
                        src={troop.icon}
                      />
                    </motion.div>
                  </div>
                ))
              )
            ) : (
              troops.map((troop, index) => (
                <div
                  className="col-12 col-md-6 col-lg-4 col-xl-3 p-0"
                  key={index}
                >
                  <motion.div
                    onClick={() => onSelect(troop)}
                    initial={{ scale: 0.5 }}
                    animate={{
                      scale: activeTroop?.id === troop.id ? 1.06 : 1,
                    }}
                    whileHover={{ scale: 1.1, filter: "brightness(1.3)" }}
                    className={classNames("troop-frame", {
                      selected: activeTroop?.id === troop.id,
                    })}
                  >
                    <img
                      className="circle scaleX w-100 m-1 "
                      alt={troop.name}
                      src={troop.icon}
                    />
                  </motion.div>
                </div>
              ))
            )}
          </div>
        </MidBox>
      </div>
      <div className="col-12 col-md-6 col-lg-4 flex relative">
        <div className="p-relative">
          <div style={{ maxHeight: 700, minHeight: 500, marginTop: 90 }}>
            {previewModel && <ModelPreview model={previewModel} />}

            {!activeTroop && (
              <div className="d-flex bg-dark-500 rounded bg-blur justify-content-center align-items-center dark-yellow fs-lg w-100 p-2 gap-3">
                <CustomIcon
                  style={{ transform: "rotate(90deg)" }}
                  icon="arrow"
                  width={30}
                  height={19}
                />{" "}
                Please select a troop from this box and choose your preferred
                level.
              </div>
            )}
          </div>
          <div className="position-absolute bottom-0 right-0 p-2 d-flex justify-content-between rounded align-items-center pt-3 pb-4 w-100">
            {activeTroop && (
              <div className="row w-100  py-0 align-items-center justify-content-center">
                {isMyArmyOnly
                  ? activeTroop?.items?.map(
                      (item: FormationCampArmyItem, index) => (
                        <div
                          className={classNames("col-6 my-1 p-0 d-flex", {
                            "justify-content-end": index % 2 === 1,
                          })}
                          key={index}
                        >
                          <div className="selection-item-frame ">
                            <img width={"90%"} src={item?.icon} alt="item" />
                          </div>
                        </div>
                      )
                    )
                  : new Array(6).fill(0).map((_, index) => (
                      <div
                        className={classNames("col-6 my-1 p-0 d-flex", {
                          "justify-content-end": index % 2 === 1,
                        })}
                        key={index}
                      >
                        <div className="selection-item-frame ">
                          <img
                            width={"90%"}
                            src={`/assets/images/arena/items/${index + 1}.png`}
                            alt="item"
                          />
                        </div>
                      </div>
                    ))}
              </div>
            )}
          </div>
        </div>
        <AnimatePresence>
          {activeTroop && (
            <>
              <div className="d-flex justify-content-between align-items-center w-100 p-2">
                {
                  <div className="selection-price-tag  text-white">
                    {ThousandsSeparator(getTroopPrice(activeTroop))}{" "}
                    <img src="/assets/images/arena/price-tag.svg" alt="cleg" />
                  </div>
                }

                <Button
                  disabled={!activeTroop.level || activeTroop.level < 1}
                  onClick={() => onSubmit(activeTroop)}
                  variant="default"
                  className=""
                >
                  Add &nbsp;+
                </Button>
              </div>
              {!isMyArmyOnly && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="bg-dark-500 bg-blur  p-2 d-flex justify-content-between rounded align-items-center pt-3 pb-4">
                    <Slider
                      className="pb-3 mx-3"
                      style={{ flex: 1, minWidth: 100 }}
                      allowCross
                      dots
                      marks={{
                        1: 1,
                        2: 2,
                        3: 3,
                        4: 4,
                        5: 5,
                        6: 6,
                        7: 7,
                        8: 8,
                        9: 9,
                        10: 10,
                        11: 11,
                        12: 12,
                      }}
                      value={activeTroop?.level}
                      onChange={(val) => changeLevel(val as number)}
                      min={0}
                      max={12}
                    />
                  </div>
                </motion.div>
              )}
            </>
          )}
        </AnimatePresence>
      </div>
      <div className="col-12 col-md-6 col-lg-4 flex ">
        <MidBox
          modal
          title="Army"
          className="w-100 px-1 p-relative"
          containerClass="px-md-2"
          containerStyle={{ padding: "0px !important" }}
        >
          <div className="d-flex justify-content-center flex-wrap gap-2">
            {selectedTroops.map((troop, index) => (
              <div
                // onClick={() => selectUnit(troop.playerTroopId)}
                className={classNames("game-bar-troop-slot p-0 ", {})}
                key={troop.tempId}
              >
                <div className="level bg-dark-yellow text-dark">
                  {troop.level}
                </div>
                <div
                  onClick={() => onRemove(troop)}
                  className="dismiss position-absolute circle bg-danger p-1 top-0 right-0"
                >
                  <CustomIcon
                    icon={"close"}
                    type="svg"
                    width={10}
                    height={10}
                  />
                </div>
                <div className="content">
                  <img
                    className="circle"
                    width={"100%"}
                    src={troop.icon}
                    alt={troop.name}
                  />
                </div>
              </div>
            ))}
            {troopLength < 10 &&
              new Array(10 - troopLength).fill(0).map((_, index) => (
                <div className="game-bar-troop-slot disabled p-0 ">
                  <div className="content"></div>
                </div>
              ))}
          </div>
          <div className="army-action-bar">
            <div className="troop-cost-container d-flex justify-content-center align-items-center flex-row">
              Total Army Cost:{" "}
              <span
                className={classNames("d-inline", {
                  "text-danger bold": totalCost > MaxCost,
                })}
              >
                {ThousandsSeparator(totalCost || 0)}{" "}
              </span>{" "}
              / {ThousandsSeparator(MaxCost)} CLEG
            </div>
          </div>
        </MidBox>
        {messages && messages[messages.length - 1] && (
          <div className="mt-4 justify-content-center align-items-center bg-dark-500 bg-blur p-2 rounded text-white">
            {messages[messages.length - 1]?.message}
          </div>
        )}
        <div className="d-flex mt-2 justify-content-center align-items-center  gap-2">
          <Button
            variant="primary"
            onClick={onReadyCheck}
            disabled={
              isReady || selectedTroops.length === 0 || totalCost > MaxCost
            }
          >
            Ready&nbsp;{" "}
            <CustomIcon icon={"blades"} type="png" width={20} height={20} />
          </Button>

          {isReady && (
            <Button variant="default" onClick={onNotReady} className="bg-100">
              Not Ready
            </Button>
          )}
        </div>
        {totalCost > MaxCost && (
          <div className="p-2 bg-dark-500 mt-3 fs-small bg-blur text-danger rounded ">
            <CustomIcon
              icon="warning"
              className="scaleX me-1"
              width={15}
              height={15}
            />{" "}
            You can't ready this match, you have not enough resources
          </div>
        )}
      </div>
    </div>
  );
};

export default TroopSelection;
