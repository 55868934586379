import React, { useEffect, useState } from "react";
import Network from "../../utils/Network";
import { ArenaQuestResponse } from "../../types/pages/MarketPlace";
import { Assets } from "../../constants/assets";
import Button from "../../components/Button";
import { CustomIcon } from "../../components/Icons";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
type Props = {};

const ArenaShop = (props: Props) => {
  const axios = new Network();
  const [data, setData] = useState<ArenaQuestResponse>();
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state: any) => state.userInfo);
  const handleGetShopData = async () => {
    setLoading(true);
    const res = await axios.CallApi<ArenaQuestResponse>({
      url: "/quest/arenashops",
      method: "get",
    });
    if (res.ok) setData(res.data);
    setLoading(false);
  };
  useEffect(() => {
    handleGetShopData();
  }, []);

  return (
    <div>
      <motion.div
        className="p-relative"
        initial={isMobile ? undefined : { opacity: 0, y: -110, zIndex: 10 }}
        animate={{ opacity: 1, y: 0, zIndex: 10 }}
        transition={{ duration: 0.3, delay: 0, zIndex: 10 }}
        exit={{ opacity: 0, y: 130 }}
      >
        <div
          style={{ minHeight: 70, marginTop: -20 }}
          className="tab-bar gap-2 pt-3 d-flex mb-4 justify-content-between align-items-center"
        >
          <div className="d-flex gap-2  align-items-center">
            <CustomIcon icon="info" width={20} height={20} />
            You can earn "Bloodgems" by paticipating in Arena battles.
          </div>
          <div className="d-flex align-items-center">
            <div className="status-frame d-flex align-items-center ms-3 ">
              <img
                width={35}
                height={40}
                style={{ marginLeft: -10 }}
                className="ms-n2"
                src={Assets.icons.energy}
              />
              <span
                className="d-block"
                style={{ fontSize: 18, marginRight: -15 }}
              >
                <CountUp
                  duration={1}
                  decimals={0}
                  separator=","
                  end={userInfo?.totalEnergy}
                />
              </span>

              <img
                width={30}
                height={30}
                className="opacity-0"
                src="/assets/images/buttons/add-button.png"
              />
            </div>
            <div className="status-frame d-flex align-items-center ms-3 ">
              <img
                width={35}
                height={40}
                style={{ marginLeft: -5 }}
                className="ms-n2"
                src={Assets.icons.bloodStone}
              />
              <span
                className="d-block"
                style={{ fontSize: 18, marginRight: -15 }}
              >
                <CountUp
                  duration={1}
                  decimals={0}
                  separator=","
                  end={userInfo?.totalBloodGem}
                />
              </span>
              <img
                width={20}
                height={30}
                className="opacity-0"
                src="/assets/images/buttons/add-button.png"
              />
            </div>
          </div>
        </div>
      </motion.div>
      <div style={{ maxWidth: 1170 }} className="row mx-auto mt-5">
        {data?.rewards.map((reward, index) => {
          return (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
              <div key={index} className="land-box py-4 ">
                <div className="lb-body d-flex flex-column align-items-center">
                  <div className="light-yellow fs-big mb-2">{reward.title}</div>
                  <img src={Assets.icons.market} alt={reward.title} />
                </div>
                <div>{reward.availableQuantity}</div>
                <div className="lb-button d-flex justify-content-center">
                  <Button className="bg-100" variant="primary">
                    <CustomIcon icon="cleg" width={20} height={20} />
                    {reward.requiredActionValue}
                  </Button>
                  <Button className="bg-100" variant="default">
                    <img src={Assets.icons.bloodStone} width={20} height={20} />

                    {reward.requiredActionValue}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ArenaShop;
