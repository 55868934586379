import React, { useEffect, useRef, useState } from "react";
import LandBox from "../../components/Boxes/LandBox";
import { motion } from "framer-motion";
import Button from "../../components/Button";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Alert, Badge, Collapse, FormCheck, Tab, Tabs } from "react-bootstrap";
import Network, { FTPURL, IsBeta } from "../../utils/Network";
import { roundFloat } from "../../utils/round-float";
import { isMobile } from "react-device-detect";
import useToast from "../../hooks/useToast";
import Dialog from "../../components/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/user/action";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Countdown from "react-countdown";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MidBox from "../../components/Boxes/MidBox";
import ThousandsSeparator from "../../utils/thousands-separator";
import ReactPaginate from "react-paginate";
import { Prev } from "react-bootstrap/esm/PageItem";
import Decimal from "decimal.js-light";
import TroopBox from "../../components/Boxes/TroopBox";
import { useDebounce } from "use-debounce";
import useWindowSize from "../../hooks/useWindowSize";
import MarketPlaceTabs from "../../components/MarketPlace/MarketPlaceTabs";
import {
  ItemBonusStat,
  ItemBonusStatIcons,
  ItemSlotEnum,
  ItemSlotNames,
  ItemType,
  PlayerItemType,
} from "../../types/items";
import { CustomIcon } from "../../components/Icons";
import ItemCard from "../../components/ItemCard";
import { Helmet } from "react-helmet";
import { ConfirmDialogType } from "../../types/global";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import ArenaShop from "./arena-shop";
type Props = {};

const slots = [
  {
    title: ItemSlotNames[ItemSlotEnum.Helmet],
    imageSrc: "https://core.chainoflegends.com/assets/items/item16-level8.png",
    value: ItemSlotEnum.Helmet,
  },
  {
    title: ItemSlotNames[ItemSlotEnum.Chest],
    imageSrc: "https://core.chainoflegends.com/assets/items/item11-level8.png",
    value: ItemSlotEnum.Chest,
  },
  {
    title: ItemSlotNames[ItemSlotEnum.Weapon],
    imageSrc: "https://core.chainoflegends.com/assets/items/item17-level8.png",
    value: ItemSlotEnum.Weapon,
  },
  {
    title: ItemSlotNames[ItemSlotEnum.Legs],
    imageSrc: "https://core.chainoflegends.com/assets/items/item14-level8.png",
    value: ItemSlotEnum.Legs,
  },
  {
    title: ItemSlotNames[ItemSlotEnum.Boots],
    imageSrc: "https://core.chainoflegends.com/assets/items/item12-level8.png",
    value: ItemSlotEnum.Boots,
  },
  {
    title: ItemSlotNames[ItemSlotEnum.Gloves],
    imageSrc: "https://core.chainoflegends.com/assets/items/item15-level8.png",
    value: ItemSlotEnum.Gloves,
  },
];

const bonusStats = Object.keys(ItemBonusStat)
  .map((key) => ({
    title: ItemBonusStat[key],
    imageSrc: ItemBonusStatIcons[key],
    value: key,
  }))
  .filter((item) => item.value !== "3");
const MarketPlace = (props: Props) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [activeTab, setActiveTab] = useState(id ? parseInt(id) : 1);
  const captchaRef = useRef("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [troopOrders, setTroopOrders] = useState([]);
  const [itemOrders, setItemOrders] = useState([]);
  const [type, setType] = useState<0 | 1 | 2 | 3 | 5|6>(0);
  const [btnLoading, setBtnLoading] = useState("");

  const [buyDialog, setBuyDialog] = useState({
    open: false,
    troopId: 3,
    price: 0,
    qty: 1,
    isItem: false,
    itemId: 1,
    level: 1,
  });
  const preLvlRangeValue = localStorage.getItem("levelRange");
  const preSelectedTroops = localStorage.getItem("selectedTroops");
  const preSelectedSlots = localStorage.getItem("selectedSlots");
  const preBonusStatValue = localStorage.getItem("bonusStatValue");
  const [selectedTroops, setSelectedTroops] = useState(
    preSelectedTroops
      ? JSON.parse(preSelectedTroops)
      : [6, 7, 11, 3, 13, 12, 4, 9, 1, 2, 5]
  );

  const [selectedSlots, setSelectedSlots] = useState(
    preSelectedSlots ? JSON.parse(preSelectedSlots) : [1, 2, 3, 4, 5, 6]
  );
  const [bonusStatValue, setBonusStatValue] = useState(
    preBonusStatValue
      ? JSON.parse(preBonusStatValue)
      : ["0", "1", "3", "4", "5", "6"]
  );
  const [troops, setTroops] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [levelRange, setLevelRange] = useState<number[]>(
    preLvlRangeValue ? JSON.parse(preLvlRangeValue) : [1, 10]
  );
  const [selectedTroopsDebounced] = useDebounce(selectedTroops, 1000);
  const [selectedSlotsDebounced] = useDebounce(selectedSlots, 1000);
  const [bonusStatValueDebounced] = useDebounce(bonusStatValue, 1000);
  const [levelRangeDebounced] = useDebounce(levelRange, 1000);
  const [captcha, setCaptcha] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [orderType, setOrderType] = useState("buy");
  const [myOrders, setMyOrders] = useState([]);
  const [oldOrders, setOldOrders] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [paginations, setPaginations] = useState({ total: 1 });
  const cardSort = localStorage.getItem("card-sort");
  const [viewType, setViewType] = useState(cardSort ? +cardSort : 1);
  const [orderPaginations, setOrderPaginations] = useState({
    total: 1,
    page: 1,
  });
  const [unitPrice, setUnitPrice] = useState(0);
  const [data, setData] = useState<any>([]);
  const [landData, setLandData] = useState([]);
  const openToast = useToast();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.userInfo);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogType>({
    id: "0",
    open: false,
    submit: () => console.log(),
    title: "",
    msg: "",
  });
  const [loaded, setLoaded] = useState(false);
  const [sectionStatus, setSectionStatus] = useState({
    1: true,
    2: true,
    3: true,
  });
  function resetLoaded() {
    setLoaded((r) => !r);
    if (!captchaRef.current) {
      setTimeout(() => {
        resetLoaded();
      }, 1500);
    }
  }
  useEffect(() => resetLoaded(), []);
  useEffect(() => {
    captchaRef.current = captcha;
  }, [captcha]);
  const getAllItems = async () => {
    const response = await axios.CallApi({
      url: "/item",
      method: "get",
    });
    if (response.ok) {
      setAllItems(response.data);
    }
  };
  useEffect(() => {
    getAllItems();
  }, []);
  const getRange = (range, level, id) => {
    const whitelist = [2, 5, 6, 7];
    if (whitelist.find((item) => item === id)) {
      if (level < 4) {
        return range;
      }
      if (level < 7) {
        return range + 1;
      }
      if (level < 10) {
        return range + 2;
      }
      if (level >= 10) {
        return range + 3;
      }
    } else {
      return range;
    }
  };

  const axios = new Network();
  const handleOpenBuyDialog = (isItem = false) => {
    setBuyDialog((prev) => ({
      ...prev,
      isItem: isItem,
      level: 2,

      open: true,
    }));
  };
  const toogleSelectTroop = async (id: number) => {
    if (selectedTroops?.find((item: number) => item === id)) {
      return setSelectedTroops((prev) => [
        ...prev?.filter((item) => item !== id),
      ]);
    }

    setSelectedTroops((prev) => [...prev, id]);
  };
  const toogleSelectSlot = async (id: number) => {
    if (selectedSlots?.find((item: number) => item === id)) {
      return setSelectedSlots((prev) => [
        ...prev?.filter((item) => item !== id),
      ]);
    }
    setSelectedSlots((prev) => [...prev, id]);
  };
  const toogleSelectBonusStat = async (id: number | string) => {
    if (bonusStatValue?.find((item: number) => +item === +id)) {
      return setBonusStatValue((prev) => [
        ...prev?.filter((item) => +item !== +id),
      ]);
    }

    setBonusStatValue((prev) => [...prev, id]);
  };
  useEffect(() => {
    getOrders();
  }, [bonusStatValueDebounced, levelRangeDebounced, selectedSlotsDebounced]);
  const getMe = async () => {
    const response = await axios.CallApi({
      url: "/player",
      method: "get",
    });
    if (response.ok) {
      dispatch(setUserInfo(response.data));
    }
  };
  async function getMyOrders(page = 1) {
    const response = await axios.CallApi({
      url: "/market/my-orders?states=1,2&count=5&page=" + page,
      method: "get",
    });
    if (response.ok) {
      setMyOrders(response.data?.data);
      setPaginations({ total: Math.ceil(response.data?.totalCount / 5) });
    }
  }

  async function getOldOrders(page = 1) {
    const response = await axios.CallApi({
      url: "/market/my-orders?states=3,5&count=5&page=" + page,
      method: "get",
    });
    if (response.ok) {
      setOldOrders(response.data?.data);
      setPaginations({ total: Math.ceil(response.data?.totalCount / 5) });
    }
  }

  async function handleGetTroops() {
    const response = await axios.CallApi({ url: "/troop", method: "get" });
    if (response.ok) {
      setTroops(response.data);
    }
  }

  useEffect(() => {
    handleGetTroops();
  }, []);

  async function handleCancelOrder(id: number) {
    setBtnLoading(id?.toString());
    const response = await axios.CallApi({
      url: "/market/my-order/" + id,
      method: "delete",
    });
    openToast(response);
    if (response.ok) {
      handleRefreshData();
    }
    setConfirmDialog((prev: any) => ({ ...prev, open: false }));
    setBtnLoading("");
  }
  async function handleGetLandsData() {
    const response = await axios.CallApi({
      url: `/lands`,
      method: "get",
    });
    if (response.ok) {
      setLandData(response.data);
    }
    return response.data;
  }
  const handleGetData = async (newLandData: any) => {
    setLoading(true);

    const response = await axios.CallApi({
      url: `/land/${activeTab}/orders`,
      method: "get",
    });
    if (response.ok) {
      const landInfo = newLandData?.find((item) => item?.id === activeTab);
      if (id && activeTab !== parseInt(id)) return;
      if (activeTab === 1) {
        setData([
          {
            ...response.data,
            name: landInfo?.name,
            image: FTPURL + "/" + landInfo?.photoFile,
            cleg: response.data.clegResourceCount,
            stone: response.data.stoneResourceCount,
            metal: response.data.ironResourceCount,
            rate: response.data.productionRatePercent,
            cycle: response.data.cycleNumber,
            price: roundFloat(response.data.clegPrice),
          },
        ]);
      } else {
        setData(
          response.data?.map((item: any) => ({
            ...item,
            name: landInfo?.name,
            id: item.ID,
            image: FTPURL + "/" + landInfo?.photoFile,
            cleg: item?.ClegResourceCount,
            stone: item?.StoneResourceCount,
            metal: item?.IronResourceCount,
            rate: landInfo?.productionRatePercent,
            cycleNumber: item.CycleNumber,
            price: roundFloat(item.ClegPrice, 3),

            // PricePerCleg:response.data.PricePerCleg
          }))
        );
      }
    } else {
      setData([]);
    }
    setLoading(false);
  };
  async function handleRefreshData() {
    getOrders();
    await getOldOrders();
    getMyOrders();
  }
  function ToogleShowOff(num: number) {
    setSectionStatus((prev: any) => ({
      ...prev,
      [num]: !prev[num],
    }));
  }
  useEffect(() => {
    const id = searchParams.get("id");
    if (id && parseInt(id || "") !== activeTab) {
      setActiveTab(parseInt(id));
    }
  }, [location?.search]);

  useEffect(() => {
    const id: any = searchParams.get("type");
    if (parseInt(id || "") !== type) {
      setType(+id || 0);
    }
  }, [location?.search]);

  async function handleBuyOrder(id: string) {
    try {
      setBtnLoading(id);
      const response = await axios.CallApi({
        url: `/order/${id}/buy`,
        method: "get",
      });
      openToast(response);
      if (response.ok) {
        getAll();
        getMe();
      }
    } catch (e) {
    } finally {
      setConfirmDialog((prev) => ({ ...prev, open: false }));
      setBtnLoading("");
    }
  }

  async function handleClaimDesert(id: string, value?: string) {
    try {
      setBtnLoading(id);
      const response = await axios.CallApi({
        url: `/land/${id}/buy`,
        method: "post",
        body: {
          captcha: value || captcha,
        },
      });
      openToast(response);
      if (response.ok) {
        getAll();
      }
      setRefreshReCaptcha((r) => !r);
    } catch (e) {
    } finally {
      setBtnLoading("");
    }
  }
  async function placeOrder() {
    setSubmitLoading(true);
    const response = await axios.CallApi({
      url: `/market/my-orders`,
      body: {
        itemType: type,
        quantity: +quantity,
        clegPrice: +unitPrice,
        orderType: orderType === "buy" ? 1 : 2,
      },
    });

    openToast(response);
    if (response.ok) {
      setQuantity(0);
      setUnitPrice(0);
      handleRefreshData();
    }
    setSubmitLoading(false);
  }
  const setCaptchaCallBack = React.useCallback(
    (token: string) => {
      setCaptcha(token);
    },
    [loaded]
  );

  async function getOrders(page = 1) {
    setLoading(true);
    if (type === 5) {
      let calculatedLevelRange: number[] = [];
      for (
        let i = levelRangeDebounced[0];
        i < levelRangeDebounced[1] + 1;
        i++
      ) {
        calculatedLevelRange.push(i);
      }
      const searchParams = new URLSearchParams();

      searchParams.append("itemSlots", selectedSlotsDebounced?.join(","));
      searchParams.append("itemLevels", calculatedLevelRange?.join(","));
      searchParams.append("page", page + "");
      searchParams.append("count", 20 + "");
      searchParams.append("itemStats", bonusStatValueDebounced.join(","));

      const response = await axios.CallApi({
        url: `/market/view/${type}?${searchParams?.toString()}`,
        method: "get",
      });
      if (response.ok) {
        setItemOrders(
          response?.data?.data?.map((item) => ({
            ...item,
            bonusValue: item.BonusValue,
            image: item.Icon,
            item: {
              slot: item.slot,
              name: item.Name,
              bonusStat: item.BonusStat,
            },
          }))
        );
        setOrderPaginations({
          total: Math.ceil(response?.data.totalCount / 20),
          page,
        });
      }
      setLoading(false);
      return;
    }
    if (type === 3) {
      let calculatedLevelRange: number[] = [];
      for (
        let i = levelRangeDebounced[0];
        i < levelRangeDebounced[1] + 1;
        i++
      ) {
        calculatedLevelRange.push(i);
      }
      const data = await axios.CallApi({
        url: `/market/view/${type}?count=20&itemTypeIds=${selectedTroopsDebounced?.join(
          ","
        )}&itemLevels=${calculatedLevelRange?.join(",")}&page=${page}`,
        method: "get",
      });
      if (data.ok) {
        setTroopOrders(
          data?.data?.data?.map((item) => ({
            ...item,
            Range: getRange(item?.Range, item?.level, item?.troopid),
          }))
        );
        setOrderPaginations({
          total: Math.ceil(data?.data.totalCount / 20),
          page,
        });
      }
      setLoading(false);
      return;
    }
    const response = await axios.CallApi({
      url: `/market/${type}?count=10`,
      method: "get",
    });
    if (response.ok) {
      setOrders(response.data);
      getMe();
    }
    setLoading(false);
  }
  useEffect(() => {
    localStorage.setItem("levelRange", JSON.stringify(levelRange));
    localStorage.setItem("selectedTroops", JSON.stringify(selectedTroops));
    getOrders();
  }, [levelRangeDebounced, selectedTroopsDebounced]);
  useEffect(() => {
    if (type > 0) {
      getOrders();
      handleRefreshData();
    }
  }, [type]);
  async function handleBuyTroop() {
    setBtnLoading("troop");
    const response = await axios.CallApi({
      url: "/market/my-orders",
      body: {
        itemType: buyDialog.isItem ? 7 : 6,
        itemId: buyDialog.isItem ? buyDialog.itemId : buyDialog?.troopId,
        itemLevel: buyDialog?.level,
        clegPrice: +buyDialog?.price,
        quantity: +buyDialog.qty,
        orderType: 1,
      },
    });
    openToast(response);
    if (response.ok) {
      getOrders();
      setBuyDialog((prev) => ({ ...prev, open: false }));
    }
    setBtnLoading("");
  }
  async function getAll() {
    let landInformations = landData;
    if (landInformations?.length === 0) {
      landInformations = await handleGetLandsData();
    }
    handleGetData(landInformations);
  }
  function handleChangeTab(active: number) {
    setActiveTab(active);
    navigate({
      pathname: "/marketplace",
      search: "id=" + active,
    });
  }

  function handleChangeType(active: 0 | 1 | 2 | 3 | 4) {
    setType(active);
    navigate({
      pathname: "/marketplace",
      search: "type=" + active,
    });
  }

  useEffect(() => {
    getAll();
  }, [activeTab]);

  const Timer = () => (
    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
      <p className="white">Available In :</p>
      <Countdown
        date={new Date(data[0].nextAvailable)}
        onComplete={() =>
          setTimeout(() => {
            getAll();
          }, 2000)
        }
        renderer={({ formatted }) => (
          <div className="fs-big d-flex">
            <div className="time-badge">
              {formatted.hours}
              <span className="dark-yellow">Hours</span>
            </div>
            <div className="ms-2 time-badge">
              {formatted.minutes}
              <span className="dark-yellow">Minutes</span>
            </div>
            <div className="ms-2 time-badge">
              {formatted.seconds}
              <span className="dark-yellow">Seconds</span>
            </div>
          </div>
        )}
      />
    </div>
  );
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LfdbIYoAAAAACravcE9SShwtp3LI7EMz5rH4gMa"
      language="en"
      scriptProps={{
        async: true, // You can try setting async true to load it properly
        defer: true,
        appendTo: "head", // optional, default to "head", can be "head" or "body",
        nonce: undefined, // optional, default undefined
      }}
      container={{
        // optional to render inside custom element
        //   element: "[required_id_or_htmlelement]",
        parameters: {
          theme: "dark", // optional, default undefined
        },
      }}
    >
      <Helmet>
        <title key={"title"}>Marketplace | Chain Of Legends</title>
      </Helmet>
      <div className="row">
        <motion.div
          initial={isMobile ? undefined : { x: -400, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.4,
            delay: 0,
          }}
          exit={{ x: -400, opacity: 0 }}
        >
          {!IsBeta &&
            new Date("Sun, 16 Jul 2023 10:00:00 GMT").getTime() >
              Date.now() && (
              <Alert
                style={{ maxWidth: 500 }}
                variant="warning"
                className=" mx-auto"
              >
                <div className="d-flex justify-content-center felx-wrap gap-2 align-items-center">
                  <div>
                    <CustomIcon
                      icon="warning"
                      width={35}
                      height={35}
                      className="me-2 scaleX"
                    />
                    New Troops and Items <b>INO</b> will starts in:
                    <Countdown
                      date={new Date("Sun, 16 Jul 2023 10:00:00 GMT")}
                      autoStart
                      onComplete={() =>
                        setTimeout(() => {
                          getAll();
                        }, 2000)
                      }
                      renderer={({ formatted }) => (
                        <Badge
                          style={{ minWidth: 90, display: "inline-block" }}
                          bg="danger"
                          className="ms-2"
                        >
                          <CustomIcon
                            icon="timer"
                            width={15}
                            height={15}
                            className="me-1"
                          />
                          {formatted.hours}: {formatted.minutes}:{" "}
                          {formatted.seconds}
                        </Badge>
                      )}
                    />
                  </div>
                </div>
              </Alert>
            )}
        </motion.div>
        <div className="col-12 ">
          <MarketPlaceTabs />

          {type === 0 && (
            <>
              {activeTab !== 1 && (
                <motion.div
                  initial={isMobile ? undefined : { opacity: 0 }}
                  animate={{
                    x: 0,
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.4,
                  }}
                  exit={{ x: -window.innerWidth, opacity: 0 }}
                  className="col-12 d-flex align-items-center justify-content-end mt-4 mt-md-1 mb-0 mb-md-3"
                >
                  <div
                    onClick={() => {
                      setViewType(1);
                      localStorage.setItem("card-sort", "1");
                    }}
                    className={`sort-btn ${viewType === 1 && "active"}`}
                  >
                    <img
                      width={22}
                      height={22}
                      src="/assets/images/icons/grid.png"
                    />
                  </div>
                  <div
                    onClick={() => {
                      setViewType(2);
                      localStorage.setItem("card-sort", "2");
                    }}
                    className={`ms-2 sort-btn  ${viewType === 2 && "active"}`}
                  >
                    <img
                      width={22}
                      height={22}
                      src="/assets/images/icons/grid2x.png"
                    />
                  </div>
                </motion.div>
              )}
              <div
                className={
                  "row " +
                  (activeTab === 1 && isMobile && " flex-wrap-reverse ")
                }
              >
                <motion.div
                  transition={{ duration: 0.4, delay: 0.2 }}
                  className="row"
                  initial={isMobile ? undefined : { opacity: 0, y: 200 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  {loading &&
                    [1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <LandBox
                          key={index}
                          name=""
                          type={viewType}
                          loading={true}
                        />
                      </div>
                    ))}
                </motion.div>
                <motion.div
                  transition={{ duration: 0.4 }}
                  className="row first-step"
                  initial={isMobile ? undefined : { opacity: 0, y: 200 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 200 }}
                >
                  {!loading &&
                    activeTab === 1 &&
                    isMobile &&
                    data[0] &&
                    data[0].state === 2 && <Timer />}
                  {!loading &&
                    viewType === 1 &&
                    data?.map((item, key) => (
                      <div
                        key={key + "-land-box"}
                        className="col-12 col-sm-6  col-lg-4 col-xl-3"
                      >
                        <LandBox
                          className={key === 0 && "eight-step"}
                          type={viewType}
                          {...item}
                          action={
                            activeTab === 1 ? (
                              item.state === 2 ? (
                                <div
                                  style={{ marginBottom: -33 }}
                                  className="fs-big mt-4 dark-yellow "
                                >
                                  {" "}
                                  SOLD OUT ....!
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    disabled={!captcha}
                                    className="w-100 bg-100"
                                    onClick={() =>
                                      handleClaimDesert("1", captcha)
                                    }
                                    loading={
                                      btnLoading === activeTab?.toString()
                                    }
                                    variant="wide"
                                  >
                                    Buy
                                    <span className="purple-bg text-light ms-2 fs-small rounded-10 px-2">
                                      (Free)
                                    </span>
                                  </Button>
                                </div>
                              )
                            ) : (
                              <Button
                                disabled={item.SellerPlayerID === userInfo.id}
                                className="w-100 mrkt-stp4 mt-3"
                                style={{ marginBottom: -50 }}
                                onClick={() => {
                                  setConfirmDialog({
                                    id: item.id,
                                    title: "Are you sure?",
                                    msg: `Are you sure about buying ${item.name} land and paying ${item.price} CLEG ?`,
                                    open: true,
                                    submit: () => handleBuyOrder(item.id),
                                  });
                                }}
                                variant="wide"
                              >
                                {item.SellerPlayerID === userInfo.id
                                  ? "My order "
                                  : "Buy "}
                                <span className="purple-bg text-light ms-2 fs-small rounded-10 px-2">
                                  ( {item.price} CLEG)
                                </span>
                              </Button>
                            )
                          }
                        />
                      </div>
                    ))}
                  {!loading &&
                    viewType === 2 &&
                    data?.map((item, key) => (
                      <div
                        key={key + "-land-box"}
                        className="col-12 col-sm-6 mt-2 col-lg-4 col-xl-3"
                      >
                        <LandBox
                          className={
                            "land-boxt1 " + (key === 0 && "eight-step")
                          }
                          type={activeTab === 1 ? 1 : viewType}
                          {...item}
                          action={
                            activeTab === 1 ? (
                              item.state === 2 ? (
                                <div className="fs-big mt-4 dark-yellow ">
                                  {" "}
                                  SOLD OUT ....!
                                </div>
                              ) : (
                                <div>
                                  <Button
                                    className="w-100"
                                    disabled={!captcha}
                                    onClick={() =>
                                      handleClaimDesert("1", captcha)
                                    }
                                    loading={
                                      btnLoading === activeTab?.toString()
                                    }
                                    variant="wide"
                                  >
                                    Buy
                                    <span className="purple-bg text-light ms-2 fs-small rounded-10 px-2">
                                      (Free)
                                    </span>
                                  </Button>
                                </div>
                              )
                            ) : (
                              <>
                                <Button
                                  disabled={item.SellerPlayerID === userInfo.id}
                                  className="ms-1 mrkt-stp4 mt-3"
                                  size="small"
                                  style={{
                                    minWidth: "40px !important",
                                    width: 40,
                                  }}
                                  onClick={() => {
                                    setConfirmDialog({
                                      id: item.id,
                                      title: "Are you sure?",
                                      msg: `Are you sure about buying ${item.name} land and paying ${item.price} CLEG ?`,
                                      open: true,
                                      submit: () => handleBuyOrder(item.id),
                                    });
                                  }}
                                  variant="primary"
                                >
                                  {item.SellerPlayerID === userInfo.id
                                    ? "My order "
                                    : "Buy"}
                                  {/* <span className="purple-bg text-light ms-2 fs-small rounded-10 px-2">( {item.price} CLEG)</span> */}
                                </Button>
                                {/* {item.SellerPlayerID === userInfo.id && <>
                                                    <Button    className='ms-1 mb-2 ' size='small' variant='default'>
                                                     My lands
                                                    </Button>
                                                </>} */}
                              </>
                            )
                          }
                        />
                      </div>
                    ))}
                  {!loading &&
                    activeTab === 1 &&
                    !isMobile &&
                    data[0] &&
                    data[0].state === 2 && <Timer />}
                </motion.div>
              </div>
            </>
          )}
          {type > 0 && type < 3 && (
            <>
              <motion.div
                className="row mt-4 mx-auto"
                style={{ maxWidth: 800, position: "relative" }}
              >
                <motion.div
                  initial={isMobile ? undefined : { x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.1 }}
                  exit={{ x: -100, opacity: 0 }}
                  className="col-12 col-md-6"
                >
                  <MidBox noTitle>
                    <div
                      style={{ overflow: "auto", marginTop: -50 }}
                      className="table-box"
                    >
                      <table className="table table-borderless m-0 text-white">
                        <thead>
                          <tr>
                            <th scope="col">Price(CLEG)</th>
                            <th scope="col">
                              Amount({type === 1 ? "Stone" : "Iron"})
                            </th>
                            <th scope="col">Total({"CLEG"})</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading &&
                            [1, 2, 3, 3, 4, 5, 6].map((item) => (
                              <tr key={"loading" + item} className="pointer">
                                <td>
                                  <Skeleton width={"100%"} />
                                </td>
                                <td>
                                  {" "}
                                  <Skeleton width={"100%"} />{" "}
                                </td>
                                <td>
                                  {" "}
                                  <Skeleton width={"100%"} />
                                </td>
                              </tr>
                            ))}
                          {!loading &&
                            orders?.sellOrders?.map((item: any) => (
                              <tr
                                onClick={() => {
                                  setUnitPrice(item?.clegPrice);
                                  setQuantity(item?.quantity);
                                }}
                                key={item.id}
                                className="pointer "
                              >
                                <td className="text-med ">
                                  <span className="fs-lg text-danger bold">
                                    {ThousandsSeparator(item?.clegPrice)}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-lg ">
                                    {ThousandsSeparator(
                                      roundFloat(
                                        (
                                          item?.quantity - item?.filled
                                        )?.toString()
                                      )
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {ThousandsSeparator(
                                    roundFloat(
                                      item?.quantity * item?.clegPrice,
                                      0
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td
                              style={{ padding: 0 }}
                              className="bg-transparent"
                              colSpan={3}
                            >
                              <hr />
                            </td>
                          </tr>
                          {!loading &&
                            orders?.buyOrders?.map((item: any) => (
                              <tr
                                onClick={() => {
                                  setUnitPrice(item?.clegPrice);
                                  setQuantity(item?.quantity - item?.filled);
                                }}
                                key={"row" + item.id}
                                className="pointer"
                              >
                                <td className="text-med">
                                  <span className="fs-lg text-success bold">
                                    {ThousandsSeparator(item?.clegPrice)}
                                  </span>
                                </td>
                                <td>
                                  <span className="fs-lg">
                                    {ThousandsSeparator(
                                      roundFloat(
                                        (
                                          item?.quantity - item?.filled
                                        )?.toString()
                                      )
                                    )}
                                  </span>
                                </td>
                                <td>
                                  {ThousandsSeparator(
                                    roundFloat(
                                      item?.quantity * item?.clegPrice,
                                      0
                                    )
                                  )}
                                </td>
                              </tr>
                            ))}
                          {!loading &&
                            orders?.buyOrders?.length === 0 &&
                            orders?.sellOrders?.length === 0 && (
                              <tr>
                                <td
                                  colSpan={3}
                                  className="text-center bg-transparnet"
                                >
                                  <span className="dark-yellow">
                                    There is No order yet...
                                  </span>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </MidBox>
                </motion.div>
                <motion.div
                  initial={isMobile ? undefined : { x: 110, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.1 }}
                  exit={{ x: 150, opacity: 0 }}
                  className="col-12 col-md-6"
                >
                  <MidBox containerClass="mrkt-stp6-3" noTitle>
                    <ButtonGroup
                      style={{ marginTop: -40 }}
                      className="w-100 d-flex"
                    >
                      <ToggleButton
                        type="radio"
                        variant={
                          orderType === "buy" ? "success" : "outline-success"
                        }
                        name="radio"
                        size="lg"
                        checked={orderType === "buy"}
                        style={{ boxShadow: "none", flex: 1 }}
                        onClick={(e) => setOrderType("buy")}
                      >
                        Buy
                      </ToggleButton>
                      <ToggleButton
                        type="radio"
                        variant={
                          orderType === "sell" ? "danger" : "outline-danger"
                        }
                        name="radio"
                        size="lg"
                        style={{ boxShadow: "none", flex: 1 }}
                        checked={orderType === "sell"}
                        onClick={(e) => setOrderType("sell")}
                      >
                        Sell
                      </ToggleButton>
                    </ButtonGroup>
                    <div className=" mt-3 mb-1 d-flex justify-content-between align-items-center">
                      <label className="dark-yellow">Quantity</label>
                      {orderType === "sell" && (
                        <div
                          onClick={() =>
                            setQuantity(
                              type === 1
                                ? userInfo?.totalStone
                                : userInfo?.totalIron
                            )
                          }
                          className="pointer text-decoration-underline text-info"
                        >
                          Total
                        </div>
                      )}
                    </div>

                    <input
                      className="form-control form-input dark-yellow"
                      name="txtLink"
                      id="txtLink"
                      type="number"
                      step="0"
                      onKeyPress={(event) => {
                        if (!/[0-9\.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Enter the amount"
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                    />
                    <label className="dark-yellow mt-3 ">Unit Price</label>
                    <input
                      type="number"
                      step="0.001"
                      className="form-control form-input dark-yellow"
                      name="txtLink"
                      id="txtLink"
                      placeholder="Enter the price per cleg"
                      onKeyPress={(event) => {
                        if (!/[0-9\.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={unitPrice}
                      onChange={(e) => {
                        try {
                          const value = e.target.value;
                          if (value === "0.0") {
                            return setUnitPrice(+value);
                          }
                          if (value === "0.00") {
                            return setUnitPrice(+value);
                          }
                          setUnitPrice(
                            new Decimal(value || 0)
                              ?.toDecimalPlaces(3)
                              ?.toString()
                          );
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                    />
                    <div className="text-center mt-3">
                      Total :{" "}
                      {ThousandsSeparator(roundFloat(quantity * unitPrice, 1))}{" "}
                      Cleg
                    </div>
                    {quantity && unitPrice && orderType === "sell" ? (
                      <div className="text-center dark-yellow fs-tiny mt-1">
                        Fee :{" "}
                        {ThousandsSeparator(
                          roundFloat((quantity * unitPrice * 5) / 100, 1)
                        )}{" "}
                        Cleg
                      </div>
                    ) : (
                      ""
                    )}
                    <Button
                      onClick={placeOrder}
                      loading={submitLoading}
                      style={{ marginBottom: -30, marginTop: 10 }}
                      variant={
                        orderType === "buy" ? "wide-primary" : "wide-secondary"
                      }
                      className="w-100"
                    >
                      {orderType?.toUpperCase()}
                    </Button>
                  </MidBox>
                </motion.div>
                <motion.div
                  initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.1 }}
                  exit={{ y: 200, opacity: 0 }}
                  className="col-12"
                >
                  <MidBox modal noTitle>
                    <Tabs
                      style={{ marginTop: -30 }}
                      defaultActiveKey="my-orders"
                      id="uncontrolled-tab-example"
                      className=""
                      onSelect={(val) =>
                        val === "my-orders" ? getMyOrders(1) : getOldOrders(1)
                      }
                      // onClick={(e,val)=>handleRefreshData()}
                    >
                      <Tab eventKey="my-orders" title="My Orders">
                        <>
                          <div
                            style={{ overflow: "auto" }}
                            className="table-box"
                          >
                            <table className="table table-borderless m-0 text-white">
                              <thead style={{ borderRadius: 0 }}>
                                <tr>
                                  <th
                                    style={{
                                      borderRadius: "0 0  0 5px",
                                      background: "rgba(0,0,0,.3)",
                                    }}
                                    scope="col"
                                  >
                                    Order
                                  </th>
                                  <th
                                    style={{ background: "rgba(0,0,0,.3)" }}
                                    scope="col"
                                  >
                                    Quantity
                                  </th>
                                  <th
                                    style={{ background: "rgba(0,0,0,.3)" }}
                                    scope="col"
                                  >
                                    Price
                                  </th>
                                  <th
                                    style={{ background: "rgba(0,0,0,.3)" }}
                                    scope="col"
                                  >
                                    Fill
                                  </th>
                                  <th
                                    style={{ background: "rgba(0,0,0,.3)" }}
                                    scope="col"
                                  >
                                    Order Date
                                  </th>
                                  <th
                                    style={{
                                      borderRadius: "0 0 5px 0 ",
                                      background: "rgba(0,0,0,.3)",
                                    }}
                                    scope="col"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {myOrders?.length === 0 && (
                                  <tr>
                                    <td colSpan={6}>
                                      <div className="text-center py-3 dark-yellow">
                                        You haven't booked any orders yet
                                      </div>
                                    </td>
                                  </tr>
                                )}
                                {myOrders?.map((item: any) => (
                                  <tr>
                                    <td
                                      style={{ fontSize: 16 }}
                                      className={
                                        item?.orderType == 1
                                          ? "text-success"
                                          : "text-danger"
                                      }
                                    >
                                      {item?.orderType == 1 ? "Buy" : "Sell"}
                                    </td>
                                    <td>
                                      {item?.quantity} (
                                      {item?.itemType === 1 ? "Stone" : "Iron"})
                                    </td>
                                    <td>
                                      {item?.averagePrice || item?.clegPrice}
                                      Cleg
                                    </td>
                                    <td>
                                      {item?.filled} (
                                      {roundFloat(
                                        (item?.filled / item?.quantity) * 100,
                                        0
                                      )}
                                      <small>%</small>)
                                    </td>
                                    <td>
                                      {moment(
                                        new Date(item?.registerDate)
                                      )?.format("MM/DD/YYYY - HH:mm:ss")}
                                    </td>
                                    <td>
                                      <Button
                                        onClick={() =>
                                          setConfirmDialog({
                                            open: true,
                                            id: item?.id?.toString(),
                                            submit: () =>
                                              handleCancelOrder(item?.id),
                                            msg: "Are you sure to cancel your order ?",
                                            title: "Confirmation",
                                          })
                                        }
                                        variant="default"
                                      >
                                        Cancel
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <ReactPaginate
                              className="pagination mt-2 justify-content-center"
                              activeClassName="active-pg"
                              pageClassName="page-item"
                              pageLinkClassName="page-link"
                              breakClassName="page-item"
                              nextClassName="page-item"
                              nextLinkClassName="page-link"
                              breakLinkClassName="page-link"
                              previousLinkClassName="page-link"
                              previousClassName="page-item"
                              previousLabel="<"
                              nextLabel=">"
                              breakLabel="..."
                              onPageChange={(e) => getMyOrders(e.selected + 1)}
                              pageRangeDisplayed={3}
                              pageCount={paginations.total}
                              // renderOnZeroPageCount={null}
                            />
                          </div>
                        </>
                      </Tab>
                      <Tab eventKey="hisotry" title="Order History">
                        <div style={{ overflow: "auto" }} className="table-box">
                          <table className="table table-borderless m-0 text-white">
                            <thead style={{ borderRadius: 0 }}>
                              <tr>
                                <th
                                  style={{
                                    borderRadius: "0 0  0 5px",
                                    background: "rgba(0,0,0,.3)",
                                  }}
                                  scope="col"
                                >
                                  Order
                                </th>
                                <th
                                  style={{ background: "rgba(0,0,0,.3)" }}
                                  scope="col"
                                >
                                  Quantity
                                </th>
                                <th
                                  style={{ background: "rgba(0,0,0,.3)" }}
                                  scope="col"
                                >
                                  Price
                                </th>
                                <th
                                  style={{ background: "rgba(0,0,0,.3)" }}
                                  scope="col"
                                >
                                  Fill
                                </th>
                                <th
                                  style={{ background: "rgba(0,0,0,.3)" }}
                                  scope="col"
                                >
                                  Order Date
                                </th>
                                <th
                                  style={{ background: "rgba(0,0,0,.3)" }}
                                  scope="col"
                                >
                                  Fill Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {oldOrders?.length === 0 && (
                                <tr>
                                  <td colSpan={6}>
                                    <div className="text-center py-3 dark-yellow">
                                      You haven't booked any orders yet
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {oldOrders?.map((item) => (
                                <tr>
                                  <td
                                    style={{ fontSize: 16 }}
                                    className={
                                      item?.orderType == 1
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {item?.orderType == 1 ? "Buy" : "Sell"}
                                  </td>
                                  <td>
                                    {item?.quantity} (
                                    {item?.itemType === 1 ? "Stone" : "Iron"})
                                  </td>
                                  <td>
                                    {item?.averagePrice || item?.clegPrice} Cleg
                                  </td>
                                  <td>
                                    {item?.filled} (
                                    {roundFloat(
                                      (item?.filled / item?.quantity) * 100,
                                      0
                                    )}
                                    <small>%</small>)
                                  </td>
                                  <td>
                                    {moment(
                                      new Date(item?.registerDate)
                                    )?.format("MM/DD/YYYY - HH:mm:ss")}
                                  </td>
                                  <td>
                                    {moment(new Date(item?.lastUpdate))?.format(
                                      "MM/DD/YYYY - HH:mm:ss"
                                    )}
                                  </td>
                                </tr>
                              ))}{" "}
                            </tbody>
                          </table>
                          <ReactPaginate
                            className="pagination mt-2 justify-content-center"
                            activeClassName="active-pg"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            breakClassName="page-item"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLinkClassName="page-link"
                            previousLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLabel="<"
                            nextLabel=">"
                            breakLabel="..."
                            onPageChange={(e) => getOldOrders(e.selected + 1)}
                            pageRangeDisplayed={3}
                            pageCount={paginations.total}
                            // renderOnZeroPageCount={null}
                          />
                        </div>
                      </Tab>
                    </Tabs>
                  </MidBox>
                </motion.div>
              </motion.div>
            </>
          )}
          {type === 3 && (
            <>
              <div className="mrkt-stp9">
                <motion.div
                  className="p-relative"
                  initial={
                    isMobile ? undefined : { opacity: 0, y: -110, zIndex: 10 }
                  }
                  animate={{ opacity: 1, y: 0, zIndex: 10 }}
                  transition={{ duration: 0.3, delay: 0, zIndex: 10 }}
                  exit={{ opacity: 0, y: 130 }}
                >
                  <div
                    className="tab-bar pb-4 pt-4   "
                    style={{
                      marginTop: -5,
                      zIndex: 10,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <PerfectScrollbar style={{ maxWidth: "100%" }}>
                      <div className="d-flex flex-nowrap  justify-content-md-center align-items-center">
                        {troops?.map((item: any, key) => (
                          <motion.div
                            onClick={() => toogleSelectTroop(item?.id)}
                            // initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                            // animate={{ y: 0, opacity: 1 }}
                            // transition={{ duration: 0.2, delay: key * 0.05 }}
                            // exit={{ y: -200, opacity: 0 }}
                            className={`me-2 my-2 troop-selection ${
                              selectedTroops?.find((st) => st === item?.id) &&
                              "selected"
                            }`}
                          >
                            <img src={item?.icon} width="60px" height={60} />
                          </motion.div>
                        ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                </motion.div>
                <motion.div
                  className="p-relative"
                  initial={
                    isMobile ? undefined : { opacity: 0, y: -245, zIndex: 9 }
                  }
                  animate={{ opacity: 1, y: 0, zIndex: 9 }}
                  transition={{ duration: 0.4, delay: 0.2 }}
                  exit={{ opacity: 0, y: 130 }}
                >
                  <div
                    className="tab-bar pb-5 pt-4   "
                    style={{
                      marginTop: -5,
                      zIndex: 9,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <div
                      style={{ marginBottom: -20 }}
                      className="text-center dark-yellow"
                    >
                      Level
                    </div>
                    <Slider
                      range
                      marks={{
                        1: 1,
                        2: 2,
                        3: 3,
                        4: 4,
                        5: 5,
                        6: 6,
                        7: 7,
                        8: 8,
                        9: 9,
                        10: 10,
                        11: 11,
                        12: 12,
                      }}
                      max={15}
                      defaultValue={levelRange}
                      min={1}
                      className={"mx-auto mt-4 mb-3"}
                      onChange={(val) => setLevelRange(val)}
                      style={{ width: " 100%", maxWidth: 400 }}
                      step={1}
                    />
                  </div>
                </motion.div>
              </div>

              <motion.div
                style={{ marginBottom: -60 }}
                className="d-flex 
                                    mrkt-stp10
                                    mt-5 mt-md-2 mb-2 justify-content-center"
                initial={isMobile ? undefined : { x: 100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                exit={{ y: -200, opacity: 0 }}
              >
                <Button
                  variant="wide-primary"
                  onClick={() => handleOpenBuyDialog()}
                >
                  + Buy a troop
                </Button>
              </motion.div>
              <motion.div
                initial={isMobile ? undefined : { opacity: 0 }}
                animate={{
                  x: 0,
                  opacity: 1,
                }}
                transition={{
                  duration: 0.4,
                }}
                exit={{ x: -window.innerWidth, opacity: 0 }}
                className="col-12 d-flex align-items-center justify-content-end mt-2 mt-md-0 mb-0 mb-md-3"
              >
                <div
                  onClick={() => {
                    setViewType(1);
                    localStorage.setItem("card-sort", "1");
                  }}
                  className={`sort-btn ${viewType === 1 && "active"}`}
                >
                  <img
                    width={22}
                    height={22}
                    src="/assets/images/icons/grid.png"
                  />
                </div>
                <div
                  onClick={() => {
                    setViewType(2);
                    localStorage.setItem("card-sort", "2");
                  }}
                  className={`ms-2 sort-btn  ${viewType === 2 && "active"}`}
                >
                  <img
                    width={22}
                    height={22}
                    src="/assets/images/icons/grid2x.png"
                  />
                </div>
              </motion.div>
              <div className="row  mt-2">
                {loading &&
                  [1, 2, 3, 4, 5, 6, 7, 8]?.map((item, key) => (
                    <motion.div
                      initial={isMobile ? undefined : { opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      exit={{ opacity: 0 }}
                      className="col-12  col-md-4 col-lg-3"
                    >
                      <TroopBox loading type={viewType} />
                    </motion.div>
                  ))}
                {!loading && troopOrders && troopOrders?.length === 0 && (
                  <motion.div>
                    <div
                      style={{ background: "#3e382b", maxWidth: 750 }}
                      className="dark-yellow mx-auto fs-big rounded text-center py-5 my-5 "
                    >
                      Sorry no order matches your filters...
                    </div>
                  </motion.div>
                )}
                {!loading &&
                  troopOrders &&
                  troopOrders?.map((item: any, key) => (
                    <motion.div
                      initial={isMobile ? undefined : { opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      exit={{ opacity: 0 }}
                      className="col-12  col-md-6 col-xl-3"
                    >
                      <Link
                        to={`/marketplace/orderbook/troop?id=${item?.troopid}&level=${item?.level}`}
                      >
                        <TroopBox
                          //  leverage={item.troopid===7?1.5:undefined}
                          className="mx-400 mx-auto mrkt-stp8"
                          health={item?.MaxHealth}
                          ask={item?.Ask}
                          bid={item?.Bid}
                          type={viewType}
                          sellCard
                          level={item?.level}
                          maxHealth={item?.MaxHealth}
                          image={item?.Image}
                          icon={item?.Icon}
                          name={item?.Name}
                          magicResistance={item?.MagicResistance}
                          rate={""}
                          damage={item?.Damage}
                          isMarket
                          armor={item?.Armor}
                          xp={item?.Xp}
                          range={item?.Range}
                          abilities={
                            item?.Abilities
                              ? JSON.parse(item?.Abilities)
                              : undefined
                          }
                        />
                      </Link>
                    </motion.div>
                  ))}
                {troopOrders && (
                  <>
                    <ReactPaginate
                      className="pagination justify-content-center"
                      activeClassName="active-pg"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      breakClassName="page-item"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      onPageChange={(e) => getOrders(e.selected + 1)}
                      pageRangeDisplayed={3}
                      pageCount={orderPaginations.total}

                      // renderOnZeroPageCount={null}
                    />
                  </>
                )}
              </div>
            </>
          )}

          {type === 5 && (
            <div className="w-100">
              <Collapse in={showFilters}>
                <div>
                  <div
                    className="tab-bar pb-4 pt-4   "
                    style={{
                      marginTop: -5,
                      zIndex: 10,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <PerfectScrollbar style={{ maxWidth: "100%" }}>
                      <div className="d-flex  justify-content-md-center align-items-center">
                        {slots?.map((item, key) => (
                          <div className="me-2 my-1   d-flex flex-column align-items-center">
                            <motion.div
                              onClick={() => toogleSelectSlot(item.value)}
                              // initial={isMobile ? undefined : { y: 100, opacity: 0 }}
                              // animate={{ y: 0, opacity: 1 }}
                              // transition={{ duration: 0.2, delay: key * 0.05 }}
                              // exit={{ y: -200, opacity: 0 }}
                              className={`troop-selection ${
                                selectedSlots?.find(
                                  (st) => st === item.value
                                ) && "selected"
                              }`}
                            >
                              <img
                                src={item?.imageSrc}
                                width={isMobile ? "50px" : "70px"}
                                height={isMobile ? 50 : 70}
                              />
                            </motion.div>
                            <div className="fs-med fs-sm-small mt-2 mt-md-1">
                              {item.title}
                            </div>
                          </div>
                        ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                  <div
                    className="tab-bar pb-5 pt-4   "
                    style={{
                      marginTop: -5,
                      zIndex: 9,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <div
                      style={{ marginBottom: -20 }}
                      className="text-center dark-yellow"
                    >
                      Level
                    </div>
                    <Slider
                      range
                      marks={{ 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8 }}
                      max={8}
                      defaultValue={levelRange}
                      min={1}
                      className={"mx-auto mt-4 mb-3"}
                      onChange={(val) => setLevelRange(val)}
                      style={{ width: " 100%", maxWidth: 400 }}
                      step={1}
                    />
                  </div>
                  <div className="tab-bar pb-2">
                    <PerfectScrollbar style={{ maxWidth: "100%" }}>
                      <div className="d-flex py-4 justify-content-md-center gap-2 j">
                        {bonusStats.map((stat) => (
                          <div
                            style={{
                              maxWidth: 100,
                              opacity: bonusStatValue.find(
                                (item) => item === stat.value
                              )
                                ? 1
                                : 0.7,
                            }}
                            onClick={() => toogleSelectBonusStat(stat.value)}
                            className="d-flex button m-0 p-0 text-center flex-column align-items-center"
                          >
                            <div
                              style={{
                                width: 60,
                                height: 60,
                                transform: "scale(1) !important",
                                background: bonusStatValue.find(
                                  (item) => item === stat.value
                                )
                                  ? "rgba(0,0,0,0.1)"
                                  : "rgba(0,0,0,0.5)",
                              }}
                              className={`d-flex align-items-center justify-content-center item-slot-selection circle ${
                                bonusStatValue.find(
                                  (item) => item === stat.value
                                ) && "selected"
                              }`}
                            >
                              <img
                                src={stat.imageSrc}
                                width={40}
                                height={45}
                                className="circle"
                              />
                            </div>
                            <div className="fs-small mt-2">{stat.title}</div>
                          </div>
                        ))}
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
              </Collapse>
              <br />
              <div className="text-center d-flex flex-wrap justify-content-center  w-100">
                <motion.div
                  className="d-flex  "
                  initial={isMobile ? undefined : { x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                  exit={{ x: -200, opacity: 0 }}
                >
                  <Button
                    className="bg-100"
                    onClick={() => setShowFilters((prev) => !prev)}
                    variant="wide"
                  >
                    <CustomIcon
                      height={15}
                      width={24}
                      className="me-2"
                      icon={showFilters ? "arrow-up" : "arrow-down"}
                    />
                    {showFilters ? <>Hide Filters</> : <>Show Filters</>}
                  </Button>
                </motion.div>
                <motion.div
                  className="d-flex  "
                  initial={isMobile ? undefined : { x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.2 }}
                  exit={{ x: 200, opacity: 0 }}
                >
                  <Button
                    className="bg-100 ms-2"
                    variant="wide-primary"
                    onClick={() => handleOpenBuyDialog(true)}
                  >
                    + Buy an Item
                  </Button>
                </motion.div>
              </div>
              <br />
              {!loading && itemOrders && itemOrders?.length === 0 && (
                <motion.div>
                  <div
                    style={{ background: "#3e382b", maxWidth: 750 }}
                    className="dark-yellow mx-auto fs-med rounded text-center py-5 my-5 "
                  >
                    Sorry no order matches your filters...
                  </div>
                </motion.div>
              )}
              <div
                className="row
                        "
              >
                {loading &&
                  [1, 2, 3, 4, 5, 6, 7, 8].map((item: any, key) => (
                    <motion.div
                      initial={isMobile ? undefined : { opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      exit={{ opacity: 0 }}
                      className="col-12  col-md-6 col-xl-3"
                    >
                      <ItemCard loading />
                    </motion.div>
                  ))}
                {!loading &&
                  itemOrders &&
                  itemOrders?.map((item: any, key) => (
                    <motion.div
                      initial={isMobile ? undefined : { opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.2 }}
                      exit={{ opacity: 0 }}
                      className="col-12  col-md-6 col-xl-3"
                    >
                      {/* <Link to={`/marketplace/orderbook/troop?id=${item?.troopid}&level=${item?.level}`}> */}
                      <ItemCard data={item} {...item} isMarket />
                      {/* </Link> */}
                    </motion.div>
                  ))}
              </div>

              <br />
              {itemOrders && (
                <>
                  <ReactPaginate
                    className="pagination justify-content-center"
                    activeClassName="active-pg"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    breakClassName="page-item"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    onPageChange={(e) => getOrders(e.selected + 1)}
                    pageRangeDisplayed={3}
                    pageCount={orderPaginations.total}

                    // renderOnZeroPageCount={null}
                  />
                </>
              )}
            </div>
          )}
          {type === 6 && <ArenaShop />}

          <Dialog
            loading={btnLoading === confirmDialog.id}
            title={confirmDialog?.title}
            closeLabel="No"
            submitLabel="Yes"
            onSubmit={() => confirmDialog.submit()}
            open={confirmDialog?.open}
            onClose={() =>
              setConfirmDialog((prev) => ({ ...prev, open: false }))
            }
          >
            {confirmDialog?.msg}
          </Dialog>
        </div>
      </div>

      {buyDialog.open && (
        <Dialog
          loading={btnLoading === "troop"}
          onSubmit={handleBuyTroop}
          size="lg"
          title="Buy Order"
          open={buyDialog?.open}
          onClose={() => setBuyDialog((prev) => ({ ...prev, open: false }))}
        >
          {buyDialog.isItem ? (
            <div className="align-items-center d-flex justify-content-center flex-wrap">
              <div className="dark-yellow mt-1 me-auto">Select an Item :</div>
              {allItems?.map((item: ItemType) => (
                <div className="mx-2 mt-1">
                  <div
                    onClick={() =>
                      setBuyDialog((prev) => ({ ...prev, itemId: item.id }))
                    }
                    className={`w-100 troop-selection  ${
                      buyDialog?.itemId === item?.id && "selected2"
                    } `}
                  >
                    <img
                      width={40}
                      height={40}
                      src={`https://core.chainoflegends.com/assets/items/item${item.id}-level${buyDialog.level}.png`}
                    />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="align-items-center d-flex justify-content-center flex-wrap">
              <div className="dark-yellow mt-1 me-auto">Select a Troop :</div>
              {troops?.map((item) => (
                <div className="mx-2 mt-1">
                  <div
                    onClick={() =>
                      setBuyDialog((prev) => ({ ...prev, troopId: item?.id }))
                    }
                    className={`w-100 troop-selection  ${
                      buyDialog?.troopId === item?.id && "selected2"
                    } `}
                  >
                    <img width={40} height={40} src={item?.icon} />
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="align-items-center mt-4  d-flex flex-wrap">
            <div className="dark-yellow mt-1 me-3">Select level :</div>
            <Slider
              className="ms-auto"
              value={buyDialog.level}
              style={{ flex: 1, maxWidth: 550, minWidth: 250 }}
              onChange={(val) =>
                setBuyDialog((prev) => ({ ...prev, level: val }))
              }
              marks={
                buyDialog.isItem
                  ? {
                      1: 1,
                      2: 2,
                      3: 3,
                      4: 4,
                      5: 5,
                      6: 6,
                      7: 7,
                      8: 8,
                    }
                  : {
                      1: 1,
                      2: 2,
                      3: 3,
                      4: 4,
                      5: 5,
                      6: 6,
                      7: 7,
                      8: 8,
                      9: 9,
                      10: 10,
                      11: 11,
                      12: 12,
                    }
              }
              step={1}
              min={1}
              max={buyDialog.isItem ? 8 : 15}
            />
          </div>
          <div className="align-items-center mt-5 d-flex  flex-wrap">
            <div className="dark-yellow me-3">
              {buyDialog.isItem ? "Item" : "Troop"} price
              <small className="fs-tiny">
                (
                <img
                  width={15}
                  height={15}
                  src="/assets/images/icons/cleg.png"
                />
                CLEG)
              </small>
              :
            </div>
            <input
              onKeyPress={(event) => {
                if (!/[0-9\.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) =>
                setBuyDialog((prev) => ({ ...prev, price: e.target.value }))
              }
              placeholder="Enter your price"
              type="number"
              value={buyDialog.price}
              style={{ flex: 1, maxWidth: 550, minWidth: 250 }}
              className="form-control form-input dark-yellow ms-auto"
            />
          </div>
          <div className="align-items-center mt-3 d-flex flex-wrap">
            <div className="dark-yellow me-3">Quantity:</div>
            <input
              onKeyPress={(event) => {
                if (!/[0-9\.]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) =>
                setBuyDialog((prev) => ({ ...prev, qty: e.target.value }))
              }
              placeholder="Enter your price for this troop"
              type="number"
              value={buyDialog.qty}
              style={{ flex: 1, maxWidth: 550, minWidth: 250 }}
              className="form-control form-input dark-yellow ms-auto"
            />
          </div>
        </Dialog>
      )}

      <GoogleReCaptcha
        refreshReCaptcha={refreshReCaptcha}
        onVerify={setCaptchaCallBack}

      />
    </GoogleReCaptchaProvider>
  );
};

export default MarketPlace;
