import React from "react";
import ShopBoxEmptyState from "./empty-state";

type Props = {};

const ShopBox = (props: Props) => {
  return (
    <div
      style={{ minHeight: 111 }}
      className="bg-paper-2 p-2 rounded-3 d-flex flex-column align-items-center justify-content-center"
    >
      <ShopBoxEmptyState />
    </div>
  );
};

export default ShopBox;
