export interface Tournament {
    id: number;
    name: string;
    startDate: string;  // Use Date if you plan to parse these strings to Date objects
    endDate: string;    // Use Date if you plan to parse these strings to Date objects
    description: string;
    bloodStoneAmount: number;
    theterAmount: number;
    groups: Group[];
    matchesSchedules: MatchSchedule[];
    myMatches: (MatchSchedule & { resultType: MatchStatusType })[]
}

export interface Group {
    groupName: string;
    items: GroupItem[];
}

export interface GroupItem {
    username: string;
    userRankInGroup: number;
    score: number;
    matches: number;
    wins: number;
playerId: number;
    userAvatarUrl?: string;  // Optional field as it's not present for every item
}

export enum MatchStatusType {
    Ready = 1,
    Waiting = 2
}
interface MatchSchedule {
    groupName: string;
    firstPlayerId: number;
    firstUserAvatarUrl?: string; // Optional since it's not always present
    secondUserAvatarUrl?: string; // Optional since it's not always present
    firstUsername: string;
    secondPlayerId: number;
    secondUsername: string;
    result?: string;
    dates: string; // Use Date if you plan to parse this to Date objects

}
