import React from "react";
import { Assets } from "../../../constants/assets";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

type Props = {};
interface NavIconButtonProps {
  icon: string;
  link: string;
  text: string;
}

const ArenaNavbar = (props: Props) => {
  const userInfo = useSelector((state: any) => state.userInfo);

  const NavIconButton = (props: NavIconButtonProps) => {
    return (
      <Link
        to={props.link}
        className="d-flex align-items-center justify-content-center flex-column gap-1"
      >
        <motion.div
          style={{
            backgroundImage: `url(${Assets.icons.iconPlaceholder})`,
          }}
          whileHover={{
            backgroundImage: `url(${Assets.icons.activeIconPlaceholder})`,
          }}
          className="nav-button"
        >
          <img src={props.icon} width={40} height={40} />
        </motion.div>
        <div className="dark-yellow text-shadow pointer">{props.text}</div>
      </Link>
    );
  };

  return (
    <div className="d-flex pt-4 mb-4 container gap-3 mx-auto arena-bar flex-row align-items-center ">
      <NavIconButton icon={Assets.icons.home} link="/" text="Home" />
      <NavIconButton icon={Assets.icons.horn} link="/learn" text="Tutorial" />
      <div className="flex-grow-1" />
      <motion.div
        transition={{ duration: 0.3, delay: 0.3 }}
        initial={isMobile ? undefined : { y: -200 }}
        animate={{ y: 0 }}
        exit={{ y: -200 }}
      >
        <div className="status-frame d-flex align-items-center ms-3 ">
          <img
            width={35}
            height={40}
            style={{ marginLeft: -5 }}
            className="ms-n2"
            src={Assets.icons.energy}
          />
          <span style={{ fontSize: 18 }}>
            <CountUp duration={1} decimals={0} end={userInfo?.totalEnergy} />
          </span>
          <Link
            to={"/profile"}
            className={"button p-relative sixteen-step mr-n3 p-0 "}
          >
            <img
              width={30}
              height={30}
              src="/assets/images/buttons/add-button.png"
            />
          </Link>
        </div>
      </motion.div>
      <motion.div
        transition={{ duration: 0.3, delay: 0.3 }}
        initial={isMobile ? undefined : { y: -200 }}
        animate={{ y: 0 }}
        exit={{ y: -200 }}
      >
        <div className="status-frame d-flex align-items-center ms-3 ">
          <img
            width={35}
            height={40}
            style={{ marginLeft: -5 }}
            className="ms-n2"
            src={Assets.icons.bloodStone}
          />
          <span style={{ fontSize: 18 }}>
            <CountUp duration={1} decimals={0} end={userInfo?.totalBloodGem} />
          </span>
          <img
            width={20}
            height={30}
            className="opacity-0"
            src="/assets/images/buttons/add-button.png"
          />
        </div>
      </motion.div>
    </div>
  );
};

export default ArenaNavbar;
