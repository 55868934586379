import React, { useEffect, useRef, useState } from 'react'
import Network, { IsBeta } from '../utils/Network';
import { useDispatch, useSelector } from 'react-redux';
import useToast from './useToast';
import { setUserInfo } from '../redux/user/action';
import { SetQuests } from '../redux/quests/action';
import useConfetti from './useConfetti';
import useWindowSize from './useWindowSize';
import useSounds from './useSounds';


const useQuests = () => {
    const [actionLoading, setActionLoading] = useState<boolean|number>(false);
    const axios = new Network();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const quests = useSelector((state: any) => state.quests)
    const notify = useToast();
    const { showConfetti } = useConfetti()
    const {playSound} = useSounds()
    const getMe = async () => {
        const response = await axios.CallApi({
            url: "/player",
            method: "get",
        });
        if (response.ok) {
            dispatch(setUserInfo(response.data));
        }
    };

    const handleClaim = async (questId: number) => {
       
        setActionLoading(questId);
        const response = await axios.CallApi({
            url: "/quest/claim",
            body: {
                playerQuestId: questId,
            }, 
        });                                    
        notify(response);
        if (response.ok) {
            handleGetQuests();
            getMe()
            playSound('quest-done')
            showConfetti({duration:1})
        }
        setActionLoading(false);
    };

    const handleDone = async (action: number, progress?: number) => {
        setActionLoading(true);
        const response = await axios.CallApi({
            url: "/quest/done",
            body: {
                action: action,
                progress: progress
            },
        });
        notify(response);
        if (response.ok) {
            handleGetQuests();
        
        }
        setActionLoading(false);
    };

    const handleGetQuests = async () => {
        setLoading(true);
        const response = await axios.CallApi({
            url: "/quest",
            method: "get",
        });
        setLoading(false);
        if(!response.ok)return 
        dispatch(SetQuests(response.data));
    };

    return { quests, handleClaim, loading, actionLoading, handleDone, getQuests: handleGetQuests }
}

export default useQuests