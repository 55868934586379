import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import "./battle-field.css";
import { Canvas, useThree } from "@react-three/fiber";
import PolygonGrid from "./polygon-grid";
import { SlotProvider } from "./slot/context";
import GameBar from "./game-bar";
import TopBar from "./game-bar/top-bar";
import { useBattleContext } from "../context";
import {
  useTexture,
  Environment,
  ContactShadows,
  OrbitControls,
  Stats,
} from "@react-three/drei";
import { Form } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";
import { CustomIcon } from "../../components/Icons";
import BattleResultDialog from "./battle-result/battle-result-dialog";
import { StoredModelData } from "../types";
import LoaderProgress from "./loader-progress";
import { Assets } from "../../constants/assets";

const SceneBackground: React.FC = () => {
  const texture = useTexture(Assets.backgrounds.liveBattle);
  texture.encoding = THREE.sRGBEncoding;
  const { scene } = useThree();
  useEffect(() => {
    scene.background = texture;
    scene.receiveShadow = true;
  }, [scene, texture]);

  return null;
};

interface BattleFieldProps {}

const BattleField: React.FC<BattleFieldProps> = ({}) => {
  const [devMode, setDevMode] = React.useState(false);
  const { battleState } = useBattleContext();

  return (
    <SlotProvider>
      <TopBar />
      <div className="canvas-container">
        <Canvas
          shadows
          camera={{ position: [0, 14.6, 18.11], fov: 22, aspect: 1.898 }}
          gl={{ antialias: true }}
        >
          <Stats className="fbs" />
          {/* Enhanced Lighting Setup */}
          <ambientLight intensity={0.3} />
          <directionalLight
            intensity={0.9}
            position={[20.5, 12, 10]}
            castShadow
          />
          <directionalLight intensity={1} position={[-22, 10, -1]} castShadow />
          {/* <Environment preset="park" background={true} /> */}
          <ContactShadows
            position={[0.05, 0, -0.08]}
            opacity={0.41}
            // scale={20}
            blur={0.5}
            far={10}
          />
          {/* <Environment preset="forest" background /> */}
          {/* <OrbitControls  enablePan={true} /> */}
          <SceneBackground />
          <PolygonGrid devMode={devMode} />
        </Canvas>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 100,
            height: 100,
          }}
          className="d-flex text-white justify-content-center gap-2 align-items-center"
        ></div>
        <div
          style={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}
          className="d-flex text-white justify-content-center gap-2 align-items-center"
        >
          <AnimatePresence>
            {devMode && (
              <motion.div
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  borderRadius: "0 0 10px 10px",
                  maxWidth: "320px",
                }}
                initial={{ opacity: 0, y: -100 }}
                animate={{ opacity: 1, y: -20 }}
                exit={{ opacity: 0, y: -100 }}
                transition={{ duration: 0.4 }}
                className="image px-2 d-flex  pt-2 gap-2 p-1 align-items-center"
              >
                <CustomIcon icon="info" width={20} height={20} />
                <span className="fs-small dark-yellow">
                  Dev Mode Is Turned On, You can change the camera position by
                  clicking on the canvas
                </span>
              </motion.div>
            )}
          </AnimatePresence>

          {/* <div className="d-flex flex-column align-items-center">
            <Form.Check
              checked={devMode}
              onChange={(e) => {
                setDevMode(e.target.checked);
              }}
              type="switch"
              id="custom-switch"
            />
            <label htmlFor="custom-switch">Dev Mode</label>
          </div> */}
        </div>
      </div>
      
      <GameBar />
      <BattleResultDialog />
      <LoaderProgress />
    </SlotProvider>
  );
};

export default BattleField;
