import React, { useEffect, useMemo, useState } from "react";
import Button from "../../components/Button";
import { useConnector } from "../connector/context";
import { useSelector } from "react-redux";
import "../components/troop-formation/styles.css";
import TroopSelection from "../components/troop-formation";
import { FormationCampArmy, StoredModelData, Troop, Unit } from "../types";
import getTroopPrice from "../utils/get-troop-price";
import { useBattleContext } from "../context";
import { useEventBus } from "../hooks/useEventBusListener";
import { EventNames } from "../utils/event-bus";
type Props = {};


export const createTempID = () => Math.random().toString(36).substring(2, 15);

const MaxTroops = 10;
const TroopsFormation = ({}: Props) => {
  const { connector } = useConnector();
  const userData = useSelector((state: any) => state.userInfo);
  const [selectedTroops, setSelectedTroops] = useState<Troop[]>([]);
  const [activeTroop, setActiveTroop] = useState<Troop | FormationCampArmy>();
  const [isReady, setIsReady] = useState(false);
  const onReadyCheck = () => {
    if (selectedTroops.length === 0) return;
    if (isMyArmyOnly) {
      connector?.submitMyArmy(
        userData.username,
        selectedTroops.map((t) => t.playerTroopId!)
      );
    } else {
      localStorage.setItem("last-deck", JSON.stringify(selectedTroops));
      connector?.submitFormation(
        userData.username,
        selectedTroops.map((t) => ({
          id: +t.id,
          level: +t.level,
        }))
      );
    }
    setIsReady(true);
  };

  const {
    battleModeInfo,
    rules,
    troops: army,
  } = useEventBus(EventNames.StartFormation) || {};

  const isMyArmyOnly = battleModeInfo?.myArmy;

  const getSelectedDeck = () => {
    const deck = localStorage.getItem("last-deck");
    if (deck) return JSON.parse(deck);
    return undefined;
  };
  const checkDeck = () => {
    const deck = getSelectedDeck();
    if (!deck) return;
    setSelectedTroops(
      deck.map((unit) => ({
        ...unit,
        tempId: createTempID(),
      }))
    );
    return deck.map((unit) => ({
      ...unit,
      tempId: createTempID(),
    }));
  };

  useEffect(() => {
    if (battleModeInfo && !isMyArmyOnly) checkDeck();
  }, [battleModeInfo, isMyArmyOnly]);

  const handleTroopSelect = (troop: Troop | FormationCampArmy) => {
    if (selectedTroops.length >= MaxTroops) return;

    setSelectedTroops([
      ...selectedTroops,
      {
        ...troop,
        id: troop.id || troop.troopId,
        tempId: createTempID(),
        clegCost: getTroopPrice(troop),
      },
    ]);
    if(isMyArmyOnly){
      setActiveTroop(undefined)
    }
  };

  const onNotReady = () => {
    setIsReady(false);
    connector?.notReady();
  };

  const changeLevel = (level: number) => {
    if (activeTroop)
      setActiveTroop((prev) => ({ ...prev, level: level } as any));
  };

  const handleActiveTroop = (troop: Troop | FormationCampArmy) => {
    const troopId = "id" in troop ? troop.id : troop.troopId;
    const activeTroopId = activeTroop
      ? "id" in activeTroop
        ? activeTroop.id
        : activeTroop.troopId
      : null;
    if (
      "id" in troop
        ? activeTroopId === troopId
        : activeTroop?.tempId === troop.tempId
    )
      return setActiveTroop(undefined);
    setActiveTroop(troop);
  };

  const handleRemoveTroop = (troop: Troop) => {
    setSelectedTroops(selectedTroops.filter((t) => t.tempId !== troop.tempId));
  };

  return (
    <div className="formation-container min-vh-100">
      <div className="container">
        <TroopSelection
          onNotReady={onNotReady}
          onRemove={handleRemoveTroop}
          changeLevel={changeLevel}
          selectedTroops={selectedTroops}
          onSelect={handleActiveTroop}
          activeTroop={activeTroop}
          battleModeInfo={battleModeInfo}
          onSubmit={handleTroopSelect}
          isReady={isReady}
          onReadyCheck={onReadyCheck}
          army={army}
          isMyArmyOnly={isMyArmyOnly}
        />
      </div>
    </div>
  );
};

export default TroopsFormation;
