
export const S3_BASE_URL = "https://static.chainoflegends.com"
const ASSET_PATH = S3_BASE_URL + "/images"
const VIDEO_PATH = S3_BASE_URL + "/videos"
const MODEL_PATH = S3_BASE_URL + "/models"
const ICON_PATH = ASSET_PATH + "/icons"

export const Assets = {
    models: {
        dragon: MODEL_PATH + "/Dragon_Present.glb",
        archer: MODEL_PATH + "/archer.glb?v=1.4",
        guardian: MODEL_PATH + "/guardian_present.glb?v=1.2",
        warrior: MODEL_PATH + "/warrior.glb",
        bigJack: MODEL_PATH + "/bigjack.glb",
        captain: MODEL_PATH + "/captain.glb",
        catapult: MODEL_PATH + "/catapult.glb?v=1.2",
        goblin: MODEL_PATH + "/goblin.glb",
        knight: MODEL_PATH + "/knight.glb",
        mage: MODEL_PATH + "/mage.glb",
        redHunter: MODEL_PATH + "/red-hunter.glb",
    },
    icons: {
        blades: ICON_PATH + "/svg/blades.svg",
        barracks: ICON_PATH + "/svg/barracks.svg",
        iconPlaceholder: ICON_PATH + "/png/icon-placeholder.png",
        activeIconPlaceholder: ICON_PATH + "/png/active-icon-placeholder.png?v=1.2",
        horn: ICON_PATH + "/png/horn.png",
        home: ICON_PATH + "/svg/home.svg",
        back: ICON_PATH + "/svg/back.svg",
        bloodStone: ICON_PATH + "/png/bloodstone.png",
        energy: ICON_PATH + "/png/energy.png",
        market:ICON_PATH + "/png/market.png",
    },
    placeholder: {
        brokenLink: ASSET_PATH + "/placeholders/broken-link.png",
        comingSoon: ASSET_PATH + "/placeholders/coming-soon.png",

    },
    backgrounds: {
        liveBattle: ASSET_PATH + "/backgrounds/arena-v1.3.jpeg",
    },
    leaderBoard: {
        bannerTitle: ASSET_PATH + "/campaigns/arena-campaign/arena-leaderboard-title.png?v=0.1",
        legionBannerTitle: ASSET_PATH + "/campaigns/arena-campaign/legion-leaderboard-title.png",
        cup: ASSET_PATH + "/campaigns/arena-campaign/arena-leaderboard-cup.png",
    },
    videos:{
        arenaBackground: VIDEO_PATH + "/arena.mp4"
    }
};
