import React, { useEffect, useRef, useState } from "react";
import Network, { IsBeta } from "../../../utils/Network";
import { MatchStatusType, Tournament } from "./types";
import CircularProgress from "../../../components/CircularProgress";
import truncateString from "../../../utils/truncate-string";
import { getProfileURL } from "../../../utils/profile-url";
import { CustomIcon } from "../../../components/Icons";
import LeaderBoardBanner from "./banner";
import { Link } from "react-router-dom";
import moment from "moment";
import classNames from "classnames";
type Props = {};

const Legion = (props: Props) => {
  const [tournament, setTournament] = React.useState<Tournament>();
  const [loading, setLoading] = useState(false);
  const axios = new Network();
  const isCalled = useRef(false);
  const handleGetData = async () => {
    setLoading(true);
    const response = await axios.CallApi<Tournament>({
      customHost: `https://${IsBeta?'dev':'core'}.chainoflegends.com/api/v2`,
      url: "/Tournament/GetLegion",
      method: "get",
    });
    if (response.ok) setTournament(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div>
      {loading ? (
        <div
          style={{ minHeight: 500 }}
          className="d-flex justify-content-center align-items-center"
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <LeaderBoardBanner isLegion rankingData={tournament} />

          <div className="row mt-2">
            {tournament?.myMatches && (
              <>
                <div className="col-12 my-4">
                  <div className="tournament-group-frame m-0 ">
                    <div className="title mine">My Matches</div>

                    <table className="table  table-borderless m-0   text-white   ">
                      <tr>
                        <th align="center" className="text-center">
                          Player 1
                        </th>
                        <th></th>
                        <th align="center" className="text-center">
                          Player 2
                        </th>
                        <th align="center" className="text-center">
                          Group
                        </th>
                        <th align="center" className="text-center">
                          Date
                        </th>
                        <th align="center" className="text-center">
                          Result
                        </th>
                      </tr>
                      <tbody>
                        {/* {item.items.length === 0 && (
                        <tr>
                          <td colSpan={4}>
                            <div className="w-100 d-flex py-5 fs-small align-items-center gap-1 dark-yellow justify-content-center">
                              <CustomIcon icon="info" width={20} height={20} />
                              There is not data on this group yet !
                            </div>
                          </td>
                        </tr>
                      )} */}
                        {tournament?.myMatches.map((match) => (
                          <tr>
                            <td align="center" className="">
                              <Link
                                to={"/profile/" + match.firstPlayerId}
                                className="d-flex gap-1 flex-column align-items-center justify-content-center"
                              >
                                <img
                                  width={77}
                                  className="circle"
                                  src={
                                    match.firstUserAvatarUrl
                                      ? getProfileURL(
                                          match.firstPlayerId,
                                          match.firstUserAvatarUrl
                                        )!
                                      : "/assets/images/empty-avatar.png"
                                  }
                                />
                                <span className="fs-small text-info bold">
                                  {match?.firstUsername?.startsWith("0x")
                                    ? truncateString(match.firstUsername)
                                    : match?.firstUsername ||
                                      "#" + match.firstPlayerId}
                                </span>
                              </Link>
                            </td>
                            <td align="center" className="bold fs-bigger">
                              VS
                            </td>
                            <td align="center" className="">
                              <Link
                                to={"/profile/" + match.secondPlayerId}
                                className="d-flex gap-1 flex-column align-items-center justify-content-center"
                              >
                                <img
                                  width={77}
                                  className="circle"
                                  src={
                                    match.secondUserAvatarUrl
                                      ? getProfileURL(
                                          match.secondPlayerId,
                                          match.secondUserAvatarUrl
                                        )!
                                      : "/assets/images/empty-avatar.png"
                                  }
                                />
                                <span className="fs-small text-info bold">
                                  {match?.secondUsername?.startsWith("0x")
                                    ? truncateString(match.secondUsername)
                                    : match.secondUsername ||
                                      "#" + match.secondPlayerId}
                                </span>
                              </Link>
                            </td>
                            <td align="center">
                              <a
                                href={"#" + match.groupName}
                                className="d-flex gap-1 text-white link justify-content-center align-items-center"
                              >
                                <CustomIcon
                                  icon="glory"
                                  width={20}
                                  height={20}
                                />
                                {match.groupName}{" "}
                              </a>
                            </td>
                            <td align="center">
                              <div>{moment(match.dates).fromNow()}</div>
                              <span className="fs-tiny">
                                {moment(match.dates).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}
                              </span>
                            </td>
                            <td align="center">
                              {match.result || (
                                <span
                                  className={classNames(
                                    "dark-yellow fs-small",
                                    match?.resultType ===
                                      MatchStatusType.Ready && "bold text-info"
                                  )}
                                >
                                  {match?.resultType === MatchStatusType.Ready
                                    ? "Ready"
                                    : "Waiting"}
                                </span>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
            {tournament?.groups.map((item) => (
              <div id={item.groupName} className="col-md-6 py-4 col-12 ">
                <div className="tournament-group-frame m-0 ">
                  <div className="title">{item.groupName}</div>
                  <table className="table  table-borderless m-0   text-white   ">
                    <tr>
                      <th>Username</th>
                      <th align="center">Score</th>
                      <th align="center">Matches</th>
                      <th align="center">Wins</th>
                    </tr>
                    <tbody>
                      {item.items.length === 0 && (
                        <tr>
                          <td colSpan={4}>
                            <div className="w-100 d-flex py-5 fs-small align-items-center gap-1 dark-yellow justify-content-center">
                              <CustomIcon icon="info" width={20} height={20} />
                              There is not data on this group yet !
                            </div>
                          </td>
                        </tr>
                      )}
                      {item.items.map((sub) => (
                        <tr>
                          <td className="d-flex align-items-center gap-2">
                            {/* <img
                              width={60}
                              src={"/assets/images/empty-avatar.png"}
                            /> */}
                            <img
                              width={66}
                              className="circle"
                              src={
                                sub.userAvatarUrl
                                  ? getProfileURL(
                                      sub.playerId,
                                      sub.userAvatarUrl
                                    )!
                                  : "/assets/images/empty-avatar.png"
                              }
                            />
                            <div>
                              <div className="text-info font-bold">
                                {sub.username?.startsWith("0x")
                                  ? truncateString(sub.username)
                                  : sub.username}
                              </div>
                              <span className="fs-small">
                                #{sub.userRankInGroup}
                              </span>
                            </div>
                          </td>
                          <td align="center">
                            <div className="d-flex gap-1 align-items-center">
                              {sub.score}{" "}
                              <CustomIcon icon="cup" width={20} height={20} />
                            </div>
                          </td>
                          <td align="center">{sub.matches}</td>
                          <td align="center">{sub.wins}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}

            <div className="col-12 my-4">
              <div className="tournament-group-frame m-0 ">
                <div className="title mine">Match Schedule</div>
                <table className="table  table-borderless m-0   text-white   ">
                  <tr>
                    <th align="center" className="text-center">
                      Player 1
                    </th>
                    <th></th>
                    <th align="center" className="text-center">
                      Player 2
                    </th>
                    <th align="center" className="text-center">
                      Group
                    </th>
                    <th align="center" className="text-center">
                      Date
                    </th>
                    <th align="center" className="text-center">
                      Result
                    </th>
                  </tr>
                  <tbody>
                    {/* {item.items.length === 0 && (
                        <tr>
                          <td colSpan={4}>
                            <div className="w-100 d-flex py-5 fs-small align-items-center gap-1 dark-yellow justify-content-center">
                              <CustomIcon icon="info" width={20} height={20} />
                              There is not data on this group yet !
                            </div>
                          </td>
                        </tr>
                      )} */}
                    {tournament?.matchesSchedules.map((match) => (
                      <tr>
                        <td align="center" className="">
                          <Link
                            to={"/profile/" + match.firstPlayerId}
                            className="d-flex gap-1 flex-column align-items-center justify-content-center"
                          >
                            <img
                              width={77}
                              className="circle"
                              src={
                                match.firstUserAvatarUrl
                                  ? getProfileURL(
                                      match.firstPlayerId,
                                      match.firstUserAvatarUrl
                                    )!
                                  : "/assets/images/empty-avatar.png"
                              }
                            />
                            <span className="fs-small text-info bold">
                              {match?.firstUsername?.startsWith("0x")
                                ? truncateString(match.firstUsername)
                                : match?.firstUsername ||
                                  "#" + match.firstPlayerId}
                            </span>
                          </Link>
                        </td>
                        <td align="center" className="bold fs-bigger">
                          VS
                        </td>
                        <td align="center" className="">
                          <Link
                            to={"/profile/" + match.secondPlayerId}
                            className="d-flex gap-1 flex-column align-items-center justify-content-center"
                          >
                            <img
                              width={77}
                              className="circle"
                              src={
                                match.secondUserAvatarUrl
                                  ? getProfileURL(
                                      match.secondPlayerId,
                                      match.secondUserAvatarUrl
                                    )!
                                  : "/assets/images/empty-avatar.png"
                              }
                            />
                            <span className="fs-small text-info bold">
                              {match?.secondUsername?.startsWith("0x")
                                ? truncateString(match.secondUsername)
                                : match.secondUsername ||
                                  "#" + match.secondPlayerId}
                            </span>
                          </Link>
                        </td>
                        <td align="center">
                          <a
                            href={"#" + match.groupName}
                            className="d-flex gap-1 text-white link justify-content-center align-items-center"
                          >
                            <CustomIcon icon="glory" width={20} height={20} />
                            {match.groupName}{" "}
                          </a>
                        </td>
                        <td align="center">
                          <span className="fs-tiny">
                            {moment(match.dates).format("MMMM Do YYYY, h:mm a")}
                          </span>
                        </td>
                        <td align="center">
                          {match.result || (
                            <span className="dark-yellow fs-small">
                              Upcoming
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Legion;
