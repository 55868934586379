import React, { useEffect } from "react";
import MidBox from "../../../../components/Boxes/MidBox";
import RankingBoxEmptyState from "./empty-state";
import classNames from "classnames";
import useArenaLeaderBoard from "../../../hooks/useArenaLeaderBoard";
import { Link } from "react-router-dom";
import { ProfileURL } from "../../../../utils/Network";
import CircularProgress from "../../../../components/CircularProgress";

type Props = {};

const RankingBox = (props: Props) => {
  const {
    page,
    fetch: handleGetLeaderboard,
    loading,
    data,
  } = useArenaLeaderBoard();

  useEffect(() => {
    handleGetLeaderboard(1);
  }, []);

  return (
    <MidBox
      title="Camp"
      className="w-100 h-100 min-h-100"
      noMargin
      containerClass="px-0 px-lg-4 min-h-100"
      noTitle
    >
      <img
        src="/assets/images/icons/crown.png"
        width={60}
        className="box-logo"
        alt="ranking"
      />
      <div
        style={{ minHeight: 325, marginTop: -45 }}
        className={classNames(
          "camp-box-content position-relative   rounded-3  d-flex flex-column gap-2 ",
          {
            "justify-content-center align-items-center": true,
            // !isLoading && playerTroops.length === 0,
          }
        )}
      >
        {loading && <CircularProgress/>}
        {!loading && data?.totalCount === 0 && <RankingBoxEmptyState />}
        {!loading &&
          data &&
          data?.totalCount > 0 &&
          data.data?.slice(0, 4).map((d, i) => (
            <>
              <div
                key={d.playerId}
                className={classNames(
                  { "bg-2": i % 2 === 0 },
                  "d-flex w-100 flex-row justyfy-content-between align-items-center p-1 gap-2 mt-2"
                )}
              >
                <Link to={"/profile/" + d.playerId} className="p-relative ">
                  {d?.photoFile ? (
                    <div className="p-relative">
                      <div
                        style={{
                          backgroundImage: "url(/assets/images/avatar-bg.png)",
                          width: 70,
                          height: 70,
                        }}
                        className={"main-avatar"}
                      />

                      <img
                        src={`${ProfileURL}/${d?.playerId}/${d?.photoFile}`}
                        width={"70%"}
                        height={"70%"}
                        style={{
                          position: "absolute",
                          borderRadius: "50%",
                          top: "12%",
                          right: 0,
                          left: "12%",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ width: 70, height: 70 }}
                      className={"main-avatar"}
                    />
                  )}
                  {d.rank <= 3 ? (
                    <span
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        bottom: -12,
                        right: 0,
                      }}
                      className="fs-big"
                    >
                      <img
                        width={25}
                        src={`/assets/images/icons/${
                          i === 0 ? "first" : i === 1 ? "second" : "third"
                        }-place.png`}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </Link>
                <div className="fs-med ps-1 d-flex flex-column  ">
                  {d.rank > 3 && <div>#{d.rank}</div>}

                  <Link
                    to={"/profile/" + d.playerId}
                    style={{ maxWidth: 140 }}
                    className="text-truncate text-info fs-lg"
                  >
                    {d.username && !d.username?.startsWith("0x")
                      ? d.username
                      : "Player#" + d.playerId}
                  </Link>
                  <div>
                    {d.score}{" "}
                    <img
                      src={"/assets/images/icons/cup.png"}
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
              </div>

              {i === 3 && (
                <Link to={"/arena/leader-board"} className="p-relative" style={{marginBottom:-20}}>
                  <div className=" fs-small light-yellow pointer">See All...</div>
                </Link>
              )}
            </>
          ))}
      </div>
    </MidBox>
  );
};

export default RankingBox;
