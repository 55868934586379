import classNames from "classnames";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useConnector } from "../../connector/context";
import { useSelector } from "react-redux";
import { useBattleContext } from "../../context";
import { Link } from "react-router-dom";
import { CustomIcon } from "../../../components/Icons";
import { getProfileURL } from "../../../utils/profile-url";
import Countdown from "react-countdown";
import { Assets } from "../../../constants/assets";
import useArenaLiveChecking from "../../hooks/useArenaLiveChecking";
import eventBus, { EventNames } from "../../utils/event-bus";

type Props = {};

const ArenaBottomBar = (props: Props) => {
  const [isClicked, setIsClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { connector } = useConnector();
  const [ping, setPing] = useState(0);
  const userInfo = useSelector((state: any) => state.userInfo);
  const {isFindMatchOpen}=useArenaLiveChecking()
  const { isFindingMatch, setIsFindingMatch, gameInfo } =
    useBattleContext();
  const findMatchTimeout = useRef<any>();
  const gameInfoRef = useRef(gameInfo);

  useEffect(() => {
    if (gameInfo) {
      gameInfoRef.current = gameInfo;
    }
  }, [gameInfo]);


  useEffect(() => {
    eventBus.on(EventNames.Ping, (pingValue: number) => {
      setPing(pingValue);
    });
    return () => {
      eventBus.off(EventNames.Ping, (pingValue: number) => {
        setPing(pingValue);
      });
    };
  }, []);

  const findMatch = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isFindingMatch) return;
    setIsClicked((prev) => !prev);
  };

  const startTimer = () => {
    if (!gameInfoRef.current?.gameRules.findMatchTimeout) return;
    findMatchTimeout.current = setTimeout(() => {
      setIsFindingMatch(false);
      setIsClicked(false);
      findMatchTimeout.current = null;
    }, gameInfoRef.current.gameRules.findMatchTimeout);
  };

  //TODO: move routes to config file

  const routes = [
    {
      path: "/arena/camp",
      text: "Camp",
      icon: "/assets/images/arena/icons/dart.png",
    },
    {
      path: "/arena/leader-board",
      text: "Leaderboard",
      icon: "/assets/images/icons/cup.png",
      iconClass: "max-w-70",
    },
    {
      path: "/arena/shop",
      text: "Shop",
      icon: Assets.icons.market,
      iconClass: "max-w-70",
      disabled: true,
    },
    {
      path: "/arena/watch",
      text: "Watch",
      icon: "/assets/images/arena/icons/watch.png",
      disabled: true,
    },
    {
      id: "fight",
      path: "/arena/fight",
      text: isFindingMatch ? "Finding Match" : "Fight",
      icon: isFindingMatch
        ? "/assets/images/icons/scout.png"
        : "/assets/images/icons/blades.png",
      iconClass: classNames("max-w-80 mx-auto d-block", {
        active: isFindingMatch,
      }),
      onClick: findMatch,
      disabled: false,
    },
  ];

  const onFindMatch = () => {
    //TODO: FIX THis
    if (!isFindMatchOpen) {
      return;
    }
    connector?.findMatch(
      userInfo.username,
      getProfileURL(userInfo.id, userInfo.photoFile) || ""
    );
    setIsFindingMatch(true);
    startTimer();
  };

  const cancelSearch = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    connector?.notReady();
    setIsFindingMatch(false);
    clearTimeout(findMatchTimeout.current);
    findMatchTimeout.current = null;
  };
  const onFriendlyMatch = () => {
    connector?.hostMatch(
      userInfo.username,
      getProfileURL(userInfo.id, userInfo.photoFile) || ""
    );
  };
  return (
    <div className="arena-bottom-bar relative">
      {routes.map((route, index) => (
        <Link to={route.path} key={route.path}>
          <motion.div
            initial={{ y: 200 }}
            animate={{ y: 0 }}
            className={classNames("position-relative", {
              "opacity-50 cursor-not-allowed": route.disabled,
            })}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            key={route.path}
            onClick={(e) =>
              route.disabled ? e.preventDefault() : route.onClick?.(e)
            }
          >
            <div
              style={{ zIndex: 7 }}
              className="button position-relative d-flex flex-column align-items-center justify-content-center text-center z-3 "
              key={index}
            >
              <div
                style={{
                  backgroundImage:
                    route.id === "fight"
                      ? "url(/assets/images/arena/active-square-btn.png)"
                      : undefined,
                }}
                className="arena-bottom-bar-item text-center justify-content-center  position-relative"
              >
                <img
                  src={route.icon}
                  className={classNames(route.iconClass)}
                  alt=""
                />
              </div>
              <div className="arena-bottom-bar-text">
                <span>{route.text}</span>
              </div>

              {isFindMatchOpen && route.id === "fight" && !isFindingMatch && (
                <div
                  className="position-absolute end-0 p-1 badge bg-danger"
                  style={{ top: 10 }}
                >
                  <span className="fs-md">Live 🔥</span>
                </div>
              )}
            </div>
            <AnimatePresence>
              {route.id === "fight" && isClicked && (
                <>
                  <motion.div
                    initial={{ opacity: 0, y: 100, z: 2 }}
                    animate={{ opacity: 1, y: -90 }}
                    exit={{ opacity: 0, y: 100 }}
                    transition={{ duration: 0.4, easings: "easeInOut" }}
                    style={{
                      zIndex: 1000,
                      filter: isFindMatchOpen ? "none" : "saturate(0)",
                    }}
                    onClick={onFindMatch}
                    className="d-flex button p-0  position-absolute justify-content-center align-items-center flex-column top-0  w-100 z-index-5"
                  >
                    <img
                      src="/assets/images/arena/find-match.png"
                      alt=""
                      width={53}
                    />
                    <span className="fs-small dark-yellow">Find Match</span>
                    {isFindMatchOpen ? (
                      <Countdown
                        date={new Date(gameInfo?.closeTime || "")}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <div className="fs-xs dark-yellow">
                            Close in {hours}h {minutes}m {seconds}s
                          </div>
                        )}
                        onComplete={() => {
                          setRefresh((prev) => !prev);
                        }}
                      />
                    ) : (
                      <Countdown
                        date={new Date(gameInfo?.openTime || "")}
                        renderer={({ days, hours, minutes, seconds }) => (
                          <div className="fs-xs dark-yellow">
                            Opens in {hours}h {minutes}m {seconds}s
                          </div>
                        )}
                      />
                    )}
                  </motion.div>
                  <motion.div
                    initial={{ opacity: 0, y: 100, z: 2 }}
                    animate={{ opacity: 1, y: -170 }}
                    exit={{ opacity: 0, y: 100 }}
                    transition={{ duration: 0.5 }}
                    style={{ zIndex: 1000 }}
                    onClick={onFriendlyMatch}
                    className="d-flex button z-1 p-0 justify-content-center position-absolute align-items-center flex-column top-0 w-100 "
                  >
                    <img
                      src="/assets/images/arena/friendly-match.png"
                      alt=""
                      width={53}
                    />
                    <span className="fs-small dark-yellow">Friendly Match</span>
                  </motion.div>
                </>
              )}
            </AnimatePresence>
            {}
            {route.id === "fight" && isFindingMatch && (
              <div
                onClick={(e) => cancelSearch(e)}
                className="   position-absolute circle bg-danger  cancel-search "
              >
                <CustomIcon icon={"close"} type="svg" width={10} height={10} />
              </div>
            )}
          </motion.div>
        </Link>
      ))}
    </div>
  );
};

export default ArenaBottomBar;
