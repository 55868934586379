import React, { useEffect, useState } from "react";
import TroopSlots from "./troop-slots";
import "./style.css";
import Button from "../../../components/Button";
import { useBattleContext } from "../../context";
import { useConnector } from "../../connector/context";
import TroopInfo from "./troop-info";
import { AnimatePresence, motion } from "framer-motion";
import { ConnectionStatus, MessagesVariant } from "../../types/enum";
import { MessageType } from "../../types";
import { CustomIcon } from "../../../components/Icons";
import Dialog from "../../../components/Dialog";
import eventBus, { EventNames } from "../../utils/event-bus";
import { getColorBasedOnPing, getConnectionStatusColor } from "../../utils/connection-variants";
type Props = {};

const GameBar = (props: Props) => {
  const { connector } = useConnector();
  const { battleState, connectionStatus, messages,coolDowns } = useBattleContext();
  const [ping, setPing] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const handleSurrender = () => {
    if (!connector) return;
    connector.surrender(battleState.battleId);
  };


  useEffect(() => {
    eventBus.on(EventNames.Ping, (pingValue: number) => {
      setPing(pingValue);
    });
    return () => {
      eventBus.off(EventNames.Ping, (pingValue: number) => {
        setPing(pingValue);
      });
    };
  }, []);

  const getMessageIcon = ({ variant }: MessageType) => {
    if (variant === MessagesVariant.Success)
      return (
        <CustomIcon
          className="scaleX"
          width={28}
          height={28}
          icon="success"
          color="success"
        />
      );
    if (variant === MessagesVariant.Error)
      return (
        <CustomIcon
          className="scaleX"
          width={28}
          height={28}
          icon="fail"
          color="danger"
        />
      );
    return (
      <CustomIcon
        className="scaleX"
        width={28}
        height={28}
        icon="warning"
        color="warning"
      />
    );
  };

  return (
    <div className="game-bar p-4 px-6">
      <TroopSlots key={coolDowns.troopCoolDownDate} />
      <AnimatePresence>
        {battleState.selectedTroop && (
          <motion.div
            key={battleState.selectedTroop.id} // Ensure each troop has a unique id
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            transition={{ duration: 0.3 }}
            className="flex-1"
          >
            <TroopInfo selectedTroop={battleState.selectedTroop} />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="ms-auto flex-column gap-1 text-white d-flex align-items-end">
        {messages && messages?.length > 0 && (
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.6 }}
            className="rounded px-2 py-1 d-flex align-items-center gap-1"
            style={{ background: "rgba(0,0,0,0.5)", maxWidth: 500 }}
          >
            {getMessageIcon(messages[messages.length - 1])}{" "}
            {messages[messages.length - 1]?.message}
          </motion.div>
        )}

        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center gap-3">
            <b
              className="text-shadow"
              style={{ color: getConnectionStatusColor(connectionStatus) }}
            >
              {connectionStatus}
            </b>
            <span style={{ color: getColorBasedOnPing(ping) }}>Ping: {ping}</span>
          </div>
          <Button variant="wide" onClick={() => setShowDialog(true)}>
            Surrender
          </Button>
        </div>
      </div>
      <Dialog
        noFooter
        title="Surrender"
        open={showDialog}
        onClose={() => setShowDialog(false)}
      >
        <div className="d-flex flex-column gap-3 align-items-center">
          <div className="text-center dark-yellow">
            Are you sure you want to surrender?
          </div>
          <div
            style={{ marginBottom: -40 }}
            className="d-flex justify-content-center gap-3"
          >
            <Button variant="primary" onClick={() => setShowDialog(false)}>
              No
            </Button>
            <Button
              variant="default"
              onClick={() => {
                handleSurrender();
                setShowDialog(false);
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default GameBar;
