import React from 'react'
import { isMobile } from 'react-device-detect';

interface Props {
    level:number;
    className?:string;
    onClick?:()=>void;
    isDef?:boolean;
    isReq?:boolean;
    style?:React.CSSProperties
}

const LevelBadge = (props: Props) => {
  return (
    <div onClick={props.onClick} style={{
      position: 'absolute',
      zIndex:150,
      
      bottom: -5, right: 0, left: 0, marginInline: 'auto', width: 'fit-content',
      transform:isMobile ? 'scale(0.6)':'',
      ...props.style
    }} className={`level-badge ${props.isDef &&' level-badge-def'} ${props.isReq &&' level-badge-req'} ${props.className}`}>
        {props.level}
    </div>
  )
}

export default LevelBadge