import React, { useEffect, useMemo } from "react";
import LeaderBoardTabs from "./tabs";
import MidBox from "../../../components/Boxes/MidBox";
import "./style.css";
import Network from "../../../utils/Network";
import { RankingType } from "../../types";
import ReactPaginate from "react-paginate";
import LeaderboardDataTable from "./data-table";
import useArenaLeaderBoard from "../../hooks/useArenaLeaderBoard";
import { leaderBoardTabs } from "./data";
import { useParams } from "react-router-dom";
import LeaderBoardBanner from "./banner";
import Legion from "./legion";

import { motion } from "framer-motion";
type Props = {};

const ArenaLeaderBoard = (props: Props) => {
  const {
    page,
    fetch: handleGetLeaderboard,
    loading,
    data,
  } = useArenaLeaderBoard();
  const { type } = useParams();
  useEffect(() => {
    handleGetLeaderboard(1);
  }, []);

  const activeContent = useMemo(() => {
    switch (type) {
      case leaderBoardTabs[0].type:
        return <LeaderboardDataTable handleGetLeaderboard={handleGetLeaderboard} page={page}  loading={loading} {...data} />;
      case leaderBoardTabs[1].type:
        return <Legion />;
      case leaderBoardTabs[2].type:
        return <div>Legion</div>;
    }
  }, [type, loading, data]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 ,delay:.3}}
      exit={{ opacity: 0 }}
    >
      <MidBox
        style={{ maxWidth: 1000 }}
        title="Leaderboard"
        modal
        noMargin
        className="mx-auto"
      >
        <LeaderBoardTabs />
        {activeContent}

      </MidBox>
    </motion.div>
  );
};

export default ArenaLeaderBoard;
